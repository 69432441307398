import ReactSelect from 'react-select'
import './telegram-choices.css'

export default function TelegramChoices({ chatData = {}, availableNotifications = [], onChange = (values) => {} }) {
    const initialValue = (chatData?.notificationTypes || []).map((type) => ({
        value: type,
        label: availableNotifications?.find((n) => n.value === type)?.label ?? type,
    }))
    return (
        <ReactSelect
            isMulti
            name="notifications"
            defaultValue={initialValue}
            options={availableNotifications}
            className="telegram-notifications-select w-full"
            classNamePrefix="telegram-select"
            onChange={v => onChange(v)}
            menuPlacement="auto"
            menuPortalTarget={document.querySelector('body')}
        />
    )
}
