import moment from 'moment'
import { useQuery } from 'react-query'
import { useAPI } from '../../context/APIContext'
import CircledQuestionIcon from '../icon/CircledQuestionIcon'
import ServerStatus from './ServerStatus'

export default function ServersStatus({pool}) {
    const apiClient = useAPI()
    const { isLoading, data } = useQuery(['servers-status', pool], () => {
        const startTime = moment().subtract(1, 'hours')
        const endTime = moment()
        if (pool) {
            return apiClient.get(
                `/metrics?pools=${pool}&maxPoints=5&startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`
            ).then(res => res.data)
        } else {
            return apiClient.get(
                `/metrics?maxPoints=5&startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`
            ).then(res => res.data)
        }
    })
    return (
        <>
        {(isLoading || (data && Object.entries(data).length > 0)) && (
            <div className="server-status bg-white rounded-2xl p-4 divide-y">
                <div className="server-status-header text-left px-4 py-2.5">
                    <span className="text-lg font-bold flex title">
                        Server status
                        <CircledQuestionIcon />
                    </span>
                </div>
                {isLoading ? (
                    <div>Loading...</div>
                ) : data && Object.entries(data).length > 0 ? (
                    Object.entries(data).map(([name, serverData]) => (
                        <ServerStatus key={name} name={name} metrics={serverData}></ServerStatus>
                    ))
                ) : (
                    <div>No servers configured</div>
                )}
            </div>
        )}
        </>
    )
}
