import { useHistory } from "react-router-dom";
import RadiusCard from "../../../components/card/RadiusCard";
import LargeButton from "../../../components/large-button/LargeButton";

export default function AuthMethodSelect() {
    const history = useHistory()

    return (
        <RadiusCard className="p-30 pool-registration-card">
            <div className="pool-registration">
                <LargeButton title="Authenticate using Metadata" subtitle="You will have to temporary add extra entry in your metadata file." onClick={() => history.push('/pool-registration/metadata-validation')} />
                <LargeButton title="Signing using cncli tool" subtitle="You will have to sign a message using cncli tool and your pool's keys." disabled />
            </div>
        </RadiusCard>
    )
}