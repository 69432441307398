import React from 'react'

export default function RightArowIcon() {
  return (
    <span className="icon">
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6187 7.61872C17.9604 7.27701 17.9604 6.72299 17.6187 6.38128L12.0503 0.812816C11.7085 0.471107 11.1545 0.471107 10.8128 0.812816C10.4711 1.15452 10.4711 1.70854 10.8128 2.05025L15.7626 7L10.8128 11.9497C10.4711 12.2915 10.4711 12.8455 10.8128 13.1872C11.1545 13.5289 11.7085 13.5289 12.0503 13.1872L17.6187 7.61872ZM0 7.875H17V6.125H0V7.875Z"
          fill="#3881F3"
        />
      </svg>
    </span>
  );
}
