import React, {useEffect, useState} from "react";
import RadiusCard from "../card/RadiusCard";
import CircledQuestionIcon from "../icon/CircledQuestionIcon";
import {OutboundLinkIcon} from "../icon/OutboundLinkIcon";
import "./pool-block.css";
import "../table/table.css";
import {hasData, hideable} from "../utility/utilityFunctions";
import {useAuth} from "../../context/AuthContext";
import {firestore} from "../../firebase/firebase.utils";
import { useAPI } from "../../context/APIContext";

export default function PoolBlock({ loading = false, poolData = {}, epochData = [], currentPool = {}, currentEpoch = {}, hidden = false, poolSelect}) {
  const { isAdmin } = useAuth();
  const apiClient = useAPI()
  // Data states
  const [mappedPoolData, setMappedPoolData] = useState({}); // All pools data mapped
  const [currentSlots, setCurrentSlots] = useState([]); // Currently filtered/sorted data passed to rendering
  const [currentEpochs, setCurrentEpochs] = useState([]); // Epochs for current pool/all pools is checkbox is selected
  // Table states
  const [rowPerPage, setRowPerPage] = useState(20);
  const [currentRowIndex, setCurrentRowIndex] = useState({ start: 0, end: 19 });
  const [currentRows, setCurrentRows] = useState([]);
  // Filtering and sorting states
  const [statusFilter, setStatusFilter] = useState(null);
  const [epochFilter, setEpochFilter] = useState(null);
  const [ascAt, setAscAt] = useState(false);
  const [ascEpoch, setAscEpoch] = useState(false);
  const [ascSlot, setAscSlot] = useState(false);
  const [recentSort, setRecentSort] = useState(null);
  const [showAllPools, setShowAllPools] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);

  const changeCurrentRowIndex = (startIndex, endIdex) => {
    if (startIndex <= 0) {
      startIndex = 0;
      endIdex = startIndex + rowPerPage - 1;
      if (endIdex > currentSlots.length - 1) {
        endIdex = currentSlots.length - 1;
      }
    }
    if (endIdex > currentSlots.length - 1) {
      // startIndex = assignedSlots.length - rowPerPage;
      endIdex = currentSlots.length - 1;
      if (startIndex < 0) {
        startIndex = 0;
      }
      if (startIndex > currentSlots.length - 1) {
        // startIndex = assignedSlots.length - currentRowIndex.start;
        startIndex = currentRowIndex.start;
      }
    }
    if (currentRowIndex.end === currentSlots.length - 1) {
        endIdex = startIndex + rowPerPage - 1;
    }
    if (rowPerPage >= currentSlots.length) {
      startIndex = 0;
      endIdex = currentSlots.length - 1;
    }
    setCurrentRowIndex({
      start: startIndex,
      end: endIdex,
    });
  };

  const renderRows = (startIndex, endIndex) => {
    return currentSlots.filter((slot, index) =>
            index >= startIndex && index <= endIndex
    );
  };
  
  useEffect(() => {
    setCurrentRows(renderRows(currentRowIndex.start, currentRowIndex.end));
  }, [currentRowIndex]);

  useEffect(() => {
    if (!hasData(epochData)) return;
    // Should only call on poolData change to load current data faster
    if (hasData(poolData)) {
      let tempSlots = [];
      epochData.forEach(epoch => {
        if (hasData(epoch.assignedSlots)) {
          tempSlots = tempSlots.concat(epoch.assignedSlots.map(slot => {
            slot.poolTicker = epoch.poolTicker;
            slot.poolIdBech32 = epoch.poolIdBech32;
            const modifiedComment = epoch.modifications?.slots[slot.slot]?.comment;
            if (modifiedComment) slot.comment = modifiedComment;
            slot.fees = slot.fees ? slot.fees : slot.tx_count === 0 ? 0 : undefined;
            return slot;
          }));
        }
      });
      setCurrentSlots(sortedSlot(false, tempSlots));
    } else {
      setCurrentSlots([]);
    }
    setCurrentEpochs(getCurrentOrAllEpochs());

    const mapPoolData = function() {
      const poolDataMap = {};
      // Object.keys(poolData).forEach(pool => {
        let tempSlots = [];
        epochData.forEach(epoch => {
          if (hasData(epoch.assignedSlots)) {
            tempSlots = tempSlots.concat(epoch.assignedSlots.map(slot => {
              slot.poolTicker = epoch.poolTicker;
              const modifiedComment = epoch.modifications?.slots[slot.slot]?.comment;
              if (modifiedComment) slot.comment = modifiedComment;
              slot.fees = slot.fees ? slot.fees : slot.tx_count === 0 ? 0 : undefined;
              return slot;
            }));
          }
        })
        poolDataMap[currentPool.poolId] = tempSlots;
      // })
      return poolDataMap;
    }
    setMappedPoolData(mapPoolData());
    
    // Reset filter and sorting states
    setStatusFilter(null);
    setEpochFilter(null);
    setAscAt(false);
    setAscEpoch(false);
    setAscSlot(false);
    setRecentSort(null);
  }, [epochData, currentEpoch, currentPool]);

  useEffect(() => {
    changeCurrentRowIndex(0, rowPerPage - 1);
  }, [currentSlots]);
    
  useEffect(() => {
    changeCurrentRowIndex(
      currentRowIndex.start,
      currentRowIndex.start + rowPerPage - 1
    );
  }, [rowPerPage]);
  
  function getCurrentOrAllPoolsData(){
    if (showAllPools) {
      let slotsCombined = [];
      Object.keys(mappedPoolData).forEach(pool => {
        slotsCombined = slotsCombined.concat(mappedPoolData[pool]);
      });
      return slotsCombined;
    } else {
      return mappedPoolData[currentPool.poolId] ? mappedPoolData[currentPool.poolId] : []; 
    }
  }
  
  function getCurrentOrAllEpochs(){
    if (showAllPools) {
      let epochsCombined = new Set();
      Object.keys(poolData).forEach(pool => {
        epochsCombined = [...new Set([...epochsCombined,...Object.keys(poolData[pool])])];
      });
      return epochsCombined.sort((a, b) => a > b ? -1 : 1);
    } else {
      return epochData.map(epoch => epoch.epoch).sort((a, b) => a > b ? -1 : 1);
    }
  }
  
  useEffect(() => {
    setCurrentSlots(sortedMostRecent(filtered(getCurrentOrAllPoolsData())));
    setCurrentEpochs(getCurrentOrAllEpochs());
  }, [showAllPools]);

  useEffect(() => {
      setCurrentSlots(sortedMostRecent(filtered(getCurrentOrAllPoolsData())));
  }, [epochFilter, statusFilter]);
  
  function sortedAt(value, slots) {
    setAscAt(value);
    return (slots ? slots : [...currentSlots]).sort((a, b) => {
      if (a.at === b.at) {
        if (a.slot === b.slot) { // primary determinant is slot no
          return (ascEpoch ? 1 : -1) * (a.epoch <= b.epoch ? -1 : 1); // ternary is leftover
        } else {
          return (ascSlot ? 1 : -1) * (a.slot < b.slot ? -1 : 1);
        }
      } else {
        return (value ? 1 : -1) * (a.at < b.at ? -1 : 1);
      }
    });
  }

  function sortedEpoch(value, slots) {
    setAscEpoch(value);
    return (slots ? slots : [...currentSlots]).sort((a, b) => {
      if (a.epoch === b.epoch) {
        if (a.slot === b.slot) { // primary determinant is slot no
          return (ascAt ? 1 : -1) * (a.at <= b.at ? -1 : 1); // ternary is leftover
        } else {
          return (ascSlot ? 1 : -1) * (a.slot < b.slot ? -1 : 1);
        }
      } else {
        return (value ? 1 : -1) * (a.epoch < b.epoch ? -1 : 1);
      }
    });
  }

  function sortedSlot(value, slots) {
    setAscSlot(value);
    return (slots ? slots : [...currentSlots]).sort((a, b) => {
      if (a.slot === b.slot) {
        if (a.at === b.at) { // primary determinant is slot no, then at, then epoch
          return (ascEpoch ? 1 : -1) * (a.epoch <= b.epoch ? -1 : 1);
        } else {
          return (ascAt ? 1 : -1) * (a.at < b.at ? -1 : 1);
        }
      } else {
        return (value ? 1 : -1) * (a.slot < b.slot ? -1 : 1);
      }
    });
  }
  
  function sortedMostRecent(slots) { // assumes that primary is slot no, secondary is at, ternary is epoch
    if (recentSort === "at") {
      return sortedAt(ascAt, slots ? slots : [...currentSlots]);
    }
    if (recentSort === "epoch") {
      return sortedEpoch(ascEpoch, slots ? slots : [...currentSlots]);
    }
    if (recentSort === "slot") {
      return sortedSlot(ascSlot, slots ? slots : [...currentSlots]);
    }
    return sortedSlot(ascSlot, slots ? slots : [...currentSlots]);
  }
  
  function filtered(slots) {
    return filteredStatus(filteredEpoch(slots));
  }
  
  function filteredStatus(slots) {
    return statusFilter === null ? [...slots] : slots.filter(slot => slot.status === statusFilter);
  }
  
  function filteredEpoch(slots) {
    return epochFilter === null ? [...slots] : slots.filter(slot => slot.epoch.toString() === epochFilter);
  }
  
  function updateCommentForSlot(e, updatedSlot, cComment) {
    e.preventDefault();
    if (!hasData(updatedSlot.poolIdBech32) || !hasData(updatedSlot.epoch) || !hasData(updatedSlot.slot)) {
      window.alert("There was an issue while processing your request. Please try again.")
      return
    }

    setIsLoading(true);
    if (updatedSlot.comment !== cComment) {
      apiClient.post(`/pools/${updatedSlot.poolIdBech32}/epochs/${updatedSlot.epoch}/comment/${updatedSlot.slot}`, {
        message: cComment
      }).then(function () {
        currentSlots.filter((slot, index) => {
          if (updatedSlot.slot === slot.slot) {
            updatedSlot.comment = cComment;
            currentSlots[index] = updatedSlot;
          }
        })
        setCurrentRows([...renderRows(currentRowIndex.start, currentRowIndex.end)]);
      }).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }
  
  return (
    <RadiusCard className="pool-block">
      <div className="header">
        <div>
          <span className="title sub">
            Pool Blocks <CircledQuestionIcon />
          </span>
        </div>
        <div className="filter">
          <div>
            {/* <span className="title">Show all pools <input type="checkbox" checked={showAllPools} onChange={() => setShowAllPools(!showAllPools)}/></span> */}
            <span className="title">Pool: </span>
            {poolSelect}
          </div>
          <div>
            <span className="title">Epoch: </span> 
            <select value={epochFilter === null ? "All" : epochFilter} style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={(e) => epochFilter === e.target.value || e.target.value === "all" ? setEpochFilter(null) : setEpochFilter(e.target.value)}>
              <option value="all" key="all">All</option>
              {currentEpochs.map((epoch) => (<option value={epoch} key={epoch}>Epoch {epoch}</option>))}
            </select>
          </div>
          <div>
            <span className="title">Status: </span> 
            <select value={statusFilter === null ? "All" : statusFilter} style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={(e) => statusFilter === e.target.value || e.target.value === "all" ? setStatusFilter(null) : setStatusFilter(e.target.value)}>
              <option value="all" key="all">All</option>
              <option value="minted" key="minted">Minted</option>
              <option value="failed" key="failed">Failed</option>
              <option value="planned" key="planned">Planned</option>
            </select>
          </div>
        </div>
      </div>
      <div className="horizontal-scroll">
        <table>
        <thead>
          <tr>
            <th className={recentSort === "at" ? " underline" : ""} onClick={() => { setCurrentSlots(sortedAt(!ascAt)); setRecentSort("at"); }}>Date & Time <div className={ascAt ? "asc" : "desc"}/></th>
            <th>Pool</th>
            <th className={recentSort === "epoch" ? " underline" : ""} onClick={() => { setCurrentSlots(sortedEpoch(!ascEpoch)); setRecentSort("epoch"); }}>Epoch <div className={ascEpoch ? "asc" : "desc"}/></th>
            <th className={recentSort === "slot" ? " underline" : ""} onClick={() => { setCurrentSlots(sortedSlot(!ascSlot)); setRecentSort("slot"); }}>Slot <div className={ascSlot ? "asc" : "desc"}/></th>
            <th>TX</th>
            <th>Fees</th>
            <th>Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((slot) => {
            const createdAt = new Date(slot.at).toLocaleString([], { timeStyle: "short", dateStyle: "short" }).replace(",", "");
            let updatedComment = slot.comment;
            return (
              <tr key={slot.slot}>
                <td className="data">
                  {slot.blockUrl === "N/A" || slot.blockUrl === "" ? <OutboundLinkIcon fill="#1A395C33"/> 
                          : <a href={slot.blockUrl} rel="noreferrer" target="_blank">
                            <OutboundLinkIcon/>
                          </a>}
                  <span className="text">{hideable(createdAt, hidden && slot.status === "planned")}</span>
                </td>
                <td>{slot.poolTicker}</td>
                <td>{slot.epoch}</td>
                <td>{hideable(slot.slot, hidden && slot.status === "planned")}</td>
                <td>{slot.tx_count}</td>
                <td>{isNaN(slot.fees) ? (slot.fees ? slot.fees : null) : <span>&#8371;{(slot.fees / 1000000).toFixed(2).replace(".00", "")}</span>}</td>
                <td className="status">
                  <span
                    className={`badge ${
                      slot.status === "minted"
                        ? "blue"
                        : slot.status === "failed"
                        ? "red"
                        : "orange"
                    }`}
                  >
                    {slot.status}
                  </span>
                </td>
                <>
                  { isAdmin && slot.status === "failed"
                    ? <td className="input">
                        <form onSubmit={(e) => updateCommentForSlot(e, slot, updatedComment)} >
                          <input placeholder={updatedComment} type="text" onInput={(e) => updatedComment = e.target.value}
                            style={{ width: slot.comment?.length + 1 + "ch" }}/>
                          <button type="submit" className={`yes ${isLoading ? "loading" : ""}`} disabled={isLoading} />
                        </form>
                      </td>
                    : <td>{slot.comment}</td>
                  }
                </>
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <div className="footer">
        <div className="left">
          <label>
            Row per page:
            <select value={rowPerPage} onChange={(e) => setRowPerPage(Number(e.target.value))}>
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
          </label>
          <span>
            {currentRowIndex.start + 1}-{currentRowIndex.end + 1} 0f{" "}
            {currentSlots.length}
          </span>
        </div>
        <div className="right">
          <span
            className="icon btn previus"
            onClick={() =>
              changeCurrentRowIndex(
                currentRowIndex.start - rowPerPage,
                currentRowIndex.end - rowPerPage
              )
            }
          >
            <svg
              width="10"
              height="15"
              viewBox="0 0 10 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.822284 7.46407C0.822284 7.19652 0.924438 6.92901 1.12832 6.72503L7.54718 0.306239C7.9555 -0.10208 8.61752 -0.10208 9.02567 0.306239C9.43383 0.714394 9.43383 1.37628 9.02567 1.78463L3.34591 7.46407L9.02547 13.1435C9.43363 13.5519 9.43363 14.2137 9.02547 14.6218C8.61732 15.0303 7.9553 15.0303 7.54698 14.6218L1.12812 8.2031C0.924206 7.99902 0.822284 7.73151 0.822284 7.46407Z"
                fill="#3881F3"
              />
            </svg>
          </span>
                        <span
                                className="icon btn next"
                                onClick={() => changeCurrentRowIndex(
                                                currentRowIndex.start + rowPerPage,
                                                currentRowIndex.end + rowPerPage
                                )}>
            <svg
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
            >
              <path
                      d="M9.17772 7.46407C9.17772 7.19652 9.07556 6.92901 8.87168 6.72503L2.45282 0.306239C2.0445 -0.10208 1.38248 -0.10208 0.974328 0.306239C0.566174 0.714394 0.566174 1.37628 0.974329 1.78463L6.65409 7.46407L0.974527 13.1435C0.566373 13.5519 0.566373 14.2137 0.974527 14.6218C1.38268 15.0303 2.0447 15.0303 2.45302 14.6218L8.87188 8.2031C9.07579 7.99902 9.17772 7.73151 9.17772 7.46407Z"
                      fill="#3881F3"
              />
            </svg>
          </span>
                    </div>
                </div>
            </RadiusCard>
    );
}
