import React, { useEffect, useState } from "react";
import RadiusCard from "../card/RadiusCard";
import "./node-stats.css";
import CircledQuestionIcon from "../icon/CircledQuestionIcon";
import {hasData, numberFormatter} from "../utility/utilityFunctions";
import { ResponsiveLine } from "@nivo/line";

const defaultEmptyArray = []

export default function NodeStatistics({metricData = defaultEmptyArray, metric = {}}) {
  const [graphData, setGraphData] = useState([]);
  const [tickValues, setTickValues] = useState([]);
  const [maxTick, setMaxTick] = useState(0);
  const [minGraphWidth, setMinGraphWidth] = useState("0px");

  useEffect(() => {
    if (!hasData(metricData)) {
      setGraphData([])
      return
    }
    const data = (metricData || []).map(metric => ({
      x: new Date(metric.data.bucket),
      y: metric.data.metric_value_avg
    }))
    data.sort((a, b) => a.x - b.x)

    setGraphData([{id: metric.value, data: data}])
  }, [metric, metricData]);


  return (
    <RadiusCard className="block-statistics">
      <div className="header">
        <span className="title sub">
          {metric.label} <CircledQuestionIcon />
        </span>
      </div>
      <div className="horizontal-scroll pv-5">
        <div style={{ height: "350px", minWidth: minGraphWidth }}>
          {hasData(graphData) ? <ResponsiveLine
            data={graphData}
            margin={{ top: 40, right: 40, bottom: 15, left: 50 }}
            colors={["#3178c9"]}
            curve={'monotoneX'}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              legendOffset: 12,
            }}
            axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 3,
                format: '%Y-%m-%dT%H:%M:%S',
                tickValues: 5,
            }}
            enableGridX={false}
            // gridYValues={tickValues}
            isInteractive={true}
            enablePointLabel={false}
            pointLabel={d => numberFormatter(d.y, 2)}
            pointLabelYOffset={-10}
            xScale={{
              type: "time"
            }}
            xFormat="time:%Y-%m-%dT%H:%M:%S"
            yFormat=">-.2f"
            enablePoints={true}
            pointSize={6}
            pointColor="#ffffff"
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            enableArea={true}
            areaBaselineValue={0}
            areaBlendMode="darken"
            enableCrosshair={false}
            useMesh={true}
            animate={true}
          /> : (
          <div className="no-data">
              <div>
                  <span className="title">No Data</span>
              </div>
          </div>
        )}
        </div>
      </div>
    </RadiusCard>
  );
}
