import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import RadiusCard from "../../../components/card/RadiusCard";
import HighlightedJsonView from "../../../components/highlighted-json-view/HighlightedJsonView";
import LargeButton from "../../../components/large-button/LargeButton";
import { useAPI } from "../../../context/APIContext";

const validationTokenKey = 'stakepool247-authentication'

export default function MetadataAuthentication() {
    const [inputPoolId, setInputPoolId] = useState('')
    const [validationToken, setValidationToken] = useState('')
    const [extendedMetadataContent, setExtendedMetadataContent] = useState({})
    const [error, setError] = useState(null)
    const [validationAttempted, setValidationAttempted] = useState(false)
    const apiClient = useAPI()
    const history = useHistory()

    const extendedMetadataContentWithToken = useMemo(() => {
        if (extendedMetadataContent[validationTokenKey]) {
            return extendedMetadataContent
        }
        return {...extendedMetadataContent, [validationTokenKey]: validationToken}
    }, [extendedMetadataContent, validationToken])

    const handleLookupSubmit = (event) => {
        event?.preventDefault()
        apiClient.get(`/pools/registration/lookup/${inputPoolId}`).then((res) => {
            setValidationToken(res.data.validationToken)
            setExtendedMetadataContent(res.data.metadataContent)
            setError(null)
        }).catch(e => {
            const message = e?.response?.data?.message
            if (message) {
                setError(message)
            }
            setValidationToken('')
            setExtendedMetadataContent({})
        })
    }

    const validate = () => {
        apiClient.get(`/pools/registration/validate/${inputPoolId}`).then((res) => {
            setError(null)
            console.log(res)
        }).catch(e => {
            const message = e?.response?.data?.message
            if (message) {
                setError(message)
            }
            setValidationAttempted(true)
        })
    }

    return (
        <div className="metadata-validation">
            <RadiusCard className="p-10">
                <form action="#" onSubmit={handleLookupSubmit}>
                    <label htmlFor="lookup-pool-id">What's Your Pool's ID (HEX) <span className="red">*</span></label>
                    <div className="row">
                        <input type="text" name="pool-id" value={inputPoolId} onChange={(e) => setInputPoolId(e.target.value)} id="lookup-pool-id" placeholder="Pool ID" />
                        <button className="btn" type="submit">Lookup</button>
                    </div>
                </form>
            </RadiusCard>
            {error ? (
                <div className="error">{error}</div>
            ) : null}
            {validationToken ? (
                <div>
                    <RadiusCard className="p-10">
                        <div className="pool-registration">
                            <div>
                                <h3>Current Extended Metadata File</h3>
                                <HighlightedJsonView json={extendedMetadataContent} highlightedKeys={[validationTokenKey]} />
                            </div>
                            <div>
                                <h3>Extended Metadata File with Authentication</h3>
                                <HighlightedJsonView json={extendedMetadataContentWithToken} highlightedKeys={[validationTokenKey]} />
                            </div>
                        </div>
                    </RadiusCard>
                    <button className="btn" type="button" onClick={() => validate()}>{validationAttempted ? 'Re-validate' : 'Validate'}</button>
                </div>
            ) : null}
        </div>
    )
}