import React from 'react'

export const OutboundLinkIcon = ({ fill = "#73869D" }) => {
  return (
    <span className="icon">
      <svg
        width="13"
        height="13"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0173 0H12.776C12.5153 0 12.2653 0.10354 12.081 0.287843C11.8967 0.472146 11.7932 0.722115 11.7932 0.982759C11.7932 1.2434 11.8967 1.49337 12.081 1.67767C12.2653 1.86198 12.5153 1.96552 12.776 1.96552H15.6447L6.22423 11.386C6.04075 11.5705 5.93792 11.8202 5.93827 12.0804C5.93863 12.3406 6.04214 12.59 6.22611 12.774C6.41009 12.958 6.65951 13.0615 6.91969 13.0618C7.17987 13.0622 7.42958 12.9593 7.61405 12.7759L17.0346 3.35534V6.22414C17.0346 6.48478 17.1381 6.73475 17.3224 6.91905C17.5067 7.10336 17.7567 7.2069 18.0173 7.2069C18.278 7.2069 18.5279 7.10336 18.7122 6.91905C18.8965 6.73475 19.0001 6.48478 19.0001 6.22414V0.982759C19.0001 0.722115 18.8965 0.472146 18.7122 0.287843C18.5279 0.10354 18.278 0 18.0173 0Z"
          // fill="#73869D"
          fill={fill}
        />
        <path
          d="M18.0172 9.17241C17.7566 9.17241 17.5066 9.27595 17.3223 9.46026C17.138 9.64456 17.0345 9.89453 17.0345 10.1552V17.0345H1.96552V1.96552H8.84483C9.10547 1.96552 9.35544 1.86198 9.53974 1.67767C9.72404 1.49337 9.82759 1.2434 9.82759 0.982759C9.82759 0.722115 9.72404 0.472146 9.53974 0.287843C9.35544 0.10354 9.10547 0 8.84483 0H0.982759C0.722115 0 0.472146 0.10354 0.287843 0.287843C0.10354 0.472146 0 0.722115 0 0.982759V18.0172C0 18.2779 0.10354 18.5279 0.287843 18.7122C0.472146 18.8965 0.722115 19 0.982759 19H18.0172C18.2779 19 18.5279 18.8965 18.7122 18.7122C18.8965 18.5279 19 18.2779 19 18.0172V10.1552C19 9.89453 18.8965 9.64456 18.7122 9.46026C18.5279 9.27595 18.2779 9.17241 18.0172 9.17241Z"
          // fill="#73869D"
          fill={fill}
        />
      </svg>
    </span>
  );
};
