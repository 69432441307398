import RadiusCard from "../card/RadiusCard";
import "./contact-us-form.css";
import EnvelopeIcon from "../icon/EnvelopeIcon";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Loader from "../loader/Loader";
import {useAuth} from "../../context/AuthContext";
import {firestore} from "../../firebase/firebase.utils";
import {hasData} from "../utility/utilityFunctions";
import { useAPI } from "../../context/APIContext";

export default function ContactUsForm() {
    const { currentUser } = useAuth();
    const apiClient = useAPI()
    const [email, setEmail] = useState(currentUser ? currentUser.email : "");
    const [message, setMessage] = useState("");
    const [contactMe, setContactMe] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const history = useHistory();

    async function submitMail() {
        setIsLoading(true);
        apiClient.post('/contacts', {
            email: email,
            message: message,
            contactMe: contactMe
        })
        .then(() => { resetData(); return true; })
        .catch(() => {
            setErrorText('There was an issue submitting your form. Please try again or contact us directly at <a href=\"mailto:info@stakepool247.io\">info@stakepool247.io</a>');
            return false;
        }).finally(() => { setIsLoading(false); });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (!hasData(email) || !hasData(message)) {
            setErrorText("Please fill out all fields in the form");
            return;
        }
        setErrorText("");
        const success = await submitMail();
        if (success) history.push("/contact-us/success");
    }
    
    function resetData() {
        setEmail(currentUser ? currentUser.email : "");
        setMessage("");
        setContactMe(false);
    }

    return (<> { isLoading ? <Loader/> : null }
        <RadiusCard className="contact-us-form sm-w-1-1 p-30 sm-p-10" radius="30px" bgColor={currentUser ? "" : "#F6F8FF"}>
            <header className="header p-0">
                <div>
                    <h3 className="title">Any questions? Drop a message&nbsp;or&nbsp;&nbsp;<a
                            href="https://calendly.com/stakepool247/30min" className="btn" target="_blank"
                            style={{background: "#F6F8FF", color: "#3881F3"}}>SCHEDULE A CALL</a></h3>
                    <p className="text-left">Let us know if you have any questions regarding running your pool on Cardano!
                        <br/>We have vast experience in running Cardano Stake Pools on Cardano.
                        Let’s chat and figure out which solution will be the best one for you!
                    </p>
                </div>
            </header>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="input">
                        <input value={email} type="email" placeholder="Email"
                               onInput={(e) => setEmail(e.target.value)}/>
                        <EnvelopeIcon/>
                    </div>
                    <div className="input">
                            <textarea value={message} name="Message" cols="40" rows="5" style={{width: "100%"}}
                                      onInput={(e) => setMessage(e.target.value)}/>
                    </div>
                    <div className="flex-left mb-30">
                        Would you like us to contact you?
                        <input type="checkbox" checked={contactMe} onChange={() => setContactMe(!contactMe)}/>
                    </div>
                    <p className="error small">{errorText}</p>
                    <button className="submit-btn" type="submit" disabled={isLoading}>Submit</button>
                </form>
            </div>
        </RadiusCard>
    </>);
}
