import RadiusCard from "../card/RadiusCard";
import SignIn from "../sign-in/SignIn";
import "./login-form.css";

export default function LoginForm() {
  // const history = useHistory();
  // const googleSigninBtnListener = () => {
  //   // alert("google")
  //   signInWithGoogle().then((result) => {
  //     console.log(result);
  //     const credential = result.credential;
  //     const token = credential.accessToken;
  //     const user = result.user;
  //     if (user) {
  //       history.push("/statistics");
  //     }
  //   });
  // };
    
  return (
    <RadiusCard className="login-form" radius="30px">
      <h1 className="heading">Welcome Back</h1>
      <h3 className="sub-heading">StakePool247.io</h3>
      <p className="descriptive-text">Login to your Account</p>
      <SignIn/>
      {/*<span className="or">or</span>*/}
      {/*<p className="text-center">Login with</p>*/}
      {/*<div className="social-login-btns">*/}
      {/*  <div className="social-login-btn facebook">*/}
      {/*    <img src="/img/Facebook.png" alt="" />*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className="social-login-btn google"*/}
      {/*    onClick={googleSigninBtnListener}*/}
      {/*  >*/}
      {/*    <img src="/img/google.png" alt="" />*/}
      {/*  </div>*/}
      {/*  <div className="social-login-btn apple">*/}
      {/*    <img src="/img/Apple.png" alt="" />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </RadiusCard>
  );
}
