import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CircledQuestionIcon from "../../components/icon/CircledQuestionIcon";
import Loader from "../../components/loader/Loader";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import { useAPI } from "../../context/APIContext";

export default function AlertsView() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const history = useHistory();
    const apiClient = useAPI();
    const [alerts, setAlerts] = useState([])

    useEffect(() => {
        apiClient.get('/alerts').then(res => {
            setAlerts(res.data)
            setIsLoading(false);
        }).catch((err) => {
            /* eslint no-console: off */
            console.error(err)
            setIsLoading(false);
         });
    }, [apiClient])

    return (
        <PageWrapper>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="admin-panel">
                    <div className="header">
                            <div>
                                <span className="title">
                                    Alert rules
                                    <CircledQuestionIcon />
                                </span>
                            </div>
                            <div>
                                <button className="btn" onClick={() => history.push("/alerts/edit")}>Add new alert configuration</button>
                            </div>
                    </div>
                    <div className="radius-card">
                        <div className="horizontal-scroll">
                            <table>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Device</th>
                                    <th>Severity</th>
                                    <th>Enabled</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                    {alerts.map((alert) => (
                                        <tr key={alert.id}>
                                            <td>{alert.name}</td>
                                            <td>{alert.deviceName}</td>
                                            <td>{alert.level}</td>
                                            <td className="status">{alert.enabled ? (
                                                <span className="badge blue">Yes</span>
                                            ) : (
                                                <span className="badge red">No</span>
                                            )}</td>
                                            <td>
                                                <Link to={`/alerts/edit/${alert.id}`}>Edit</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </PageWrapper>
    );
}
