import React from 'react';
import "./no-auth-wrapper.css";
import RadiusCard from "../card/RadiusCard";
import {useHistory} from "react-router-dom";

export default function NoAuthWrapper({children}) {
    const history = useHistory();
    
    return (
        <div className="no-auth-wrapper">
            <div className="left-side" style={{backgroundImage: "url(/img/stakepool247-background-tall.jpg)"}}>
                <div className="filter">
                    <RadiusCard className="content-card" radius="30px">
                        <RadiusCard className="logo-card" radius="0 0 20px 20px">
                            <img src="/img/logo.png" alt=""/>
                        </RadiusCard>
                        <p className="bottom-text">
                           Cardanao Node management and monitoring tool for SPOs, so you have more time to
                            focus on your business development.
                        </p>
                    </RadiusCard>
                </div>
            </div>
            <div className="right-side">
                <div className="logo">
                    <img src="/img/logo.png" alt=""/>
                </div>
                <div>
                    {children}
                </div>
                <div className="footer">
                    <div>
                        <button className="link" onClick={() => history.push("/contact-us")}>Contact Us</button>
                        &nbsp;|&nbsp;
                        <button className="link" onClick={() => history.push("/faq")}>FAQ</button>
                        &nbsp;|&nbsp;
                        <button className="link" onClick={() => history.push("/sign-up")}>Sign Up</button>
                        &nbsp;|&nbsp;
                        <button className="link" onClick={() => history.push("/login")}>Sign In</button>
                    </div>
                    <div>
                        <div>
                            Email <a href="mailto:info@stakepool247.io">info@stakepool247.io</a>
                        </div>
                        <div>
                            Telegram <a href="tg://resolve?domain=stakepool247">@stakepool247</a>
                        </div>
                        <div>
                            Phone <a href="tel:+37126515476">+371 26515476</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
