import React, {useEffect, useRef, useState} from 'react';
import Countdown from "react-countdown";
import "./live-stats-block.css"
import ProgressBar from "../progress-bar/ProgressBar";
import {firestore} from "../../firebase/firebase.utils";
import {hasData} from "../utility/utilityFunctions";
import { useAPI } from '../../context/APIContext';

export default function LiveStatsBlock() {
    const TOTAL_SLOTS = 432000;
    const [liveData, setLiveData] = useState({});
    const apiClient = useAPI();
    const refreshIntervalRef = useRef()

    const loadLiveData = () => {
        apiClient.get('/stats')
            .then(function (snapDataGlobal) {
                const epochData = snapDataGlobal.data.latestEpoch;
                const lLiveData = {};
                lLiveData["epoch"] = epochData.epoch;
                lLiveData["end"] = epochData.end_time;
                lLiveData["start"] = epochData.start_time;
                setLiveData(lLiveData);
            });
    }

    useEffect(() => {
        loadLiveData()
        refreshIntervalRef.current = setInterval(() => {
            loadLiveData()
        }, 60 * 1000)
        return () => {
            clearInterval(refreshIntervalRef.current)
        }
    }, []);

    return (<>
        {liveData.epoch && liveData.start && liveData.end
                ? <div className="live-stats">
                    <div className="row items-end sp-between">
                        <div className="flex-block ph-10 col items-start self-center">
                            <h2 className="sm-mv-0">Epoch {liveData.epoch}</h2>
                        </div>
                        <div className="flex-block ph-10 col items-start sm-items-center self-center">
                            <span>Started on {new Date(liveData.start * 1000).toLocaleString()}</span>
                            <span>Ends in <Countdown date={new Date(liveData.end * 1000)}
                                                     renderer={({days, hours, minutes, seconds}) =>
                                                             (days > 0 ?
                                                                     <span>{days} days, {hours}h {minutes}m {seconds}s</span>
                                                                     :
                                                                     <span>{hours}h {minutes}m {seconds}s</span>)}/>
                            </span>
                        </div>
                    </div>
                    <div className="row items-end">
                        <div className="flex-block col ph-10 items-start self-center">
                            <h2 className="sm-mv-0">Slot</h2>
                        </div>
                        <div className="flex-block col ph-10 items-start sm-items-center self-center">
                            <Countdown date={new Date(liveData.end * 1000)}
                                       renderer={({days, hours, minutes, seconds}) => {
                                           const cCurrent = TOTAL_SLOTS - ((days * 24 + hours) * 3600 + minutes * 60
                                                   + seconds);
                                           return (
                                                   <div style={{width: "150px"}}>
                                                       <div className="flex-block row items-end sp-between">
                                                           <div className="flex-block col items-start self-center">{((cCurrent
                                                                   / TOTAL_SLOTS) * 100).toFixed(2)}%
                                                           </div>
                                                           <div className="flex-block col items-start self-center">{cCurrent}/{TOTAL_SLOTS}</div>
                                                       </div>
                                                       <ProgressBar current={cCurrent} total={TOTAL_SLOTS}/>
                                                   </div>
                                           )
                                       }}/>
                        </div>
                    </div>
                </div>
                : null}
    </>);
}
