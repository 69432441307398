import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAPI } from '../../context/APIContext'
import CircledQuestionIcon from '../icon/CircledQuestionIcon'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import GridTable from '../grid-table/GridTable'
import TelegramChoices from './TelegramChoices'

const chatsHeaders = [
    { key: 'chatId', label: 'Chat ID' },
    {
        key: 'name',
        label: (
            <>
                <span className="pr-3">Chat Name</span>{' '}
                <CircledQuestionIcon text="Use `/notifications` in a chat again to update the name" />
            </>
        ),
    },
    { key: 'type', label: 'Type' },
    { key: 'notifications', width: 'minmax(150px, 3fr)', label: 'Notification Types' },
]

export default function TelegramNotifications() {
    const apiClient = useAPI()
    const queryClient = useQueryClient()
    const [chatUpdates, setChatUpdates] = useState({})
    const [activeUpdates, setActiveUpdates] = useState({})
    const telegramDataQuery = useQuery('telegramChats', () => apiClient.get('/telegram/chats').then((chats) => chats.data))
    const notificationTypesQuery = useQuery('notificationTypes', () => apiClient.get('/telegram/availableNotifications').then((notifications) => notifications.data))
    const updateNotificationsMutation = useMutation({
        mutationFn: ({chat, newNotifications}) => apiClient.put('/telegram/chats', {id: chat.id, notificationTypes: newNotifications}),
        onSuccess: (data) => {
            queryClient.setQueryData(['telegramChats'], [...telegramDataQuery.data.filter(d => d.id !== data.data.id), data.data])
            const chatUpdatesCopy = {...chatUpdates}
            delete chatUpdatesCopy[data.data.id]
            setChatUpdates(chatUpdatesCopy)
        }
    })
    const deleteNotificationsMutation = useMutation({
        mutationFn: ({chat}) => apiClient.delete(`/telegram/chats/${chat.id}`),
        onSuccess: (data) => {
            queryClient.setQueryData(['telegramChats'], [...telegramDataQuery.data.filter(d => d.id !== data.data.id)])
            const chatUpdatesCopy = {...chatUpdates}
            delete chatUpdatesCopy[data.data.id]
            setChatUpdates(chatUpdatesCopy)
        }
    })

    if (telegramDataQuery.isLoading || notificationTypesQuery.isLoading) return 'Loading...'

    if (telegramDataQuery.error) return 'An error has occurred: ' + telegramDataQuery.error.message
    if (notificationTypesQuery.error) return 'An error has occurred: ' + notificationTypesQuery.error.message

    const rows = (telegramDataQuery.data || []).sort((a, b) => a.id > b.id ? 1 : -1).map((chat) => ({
        ...chat,
        // id: chat.chatId,
        notifications: (
            <TelegramChoices
                onChange={v => setChatUpdates({...chatUpdates, [chat.id]: {v}})}
                availableNotifications={notificationTypesQuery.data}
                chatData={chat}
            />
        ),
        onSubmit: async (e, row) => {
            const mutation = updateNotificationsMutation.mutateAsync({chat, newNotifications: chatUpdates[chat.id]?.v?.map(v => v.value)})
            setActiveUpdates({...activeUpdates, [chat.id]: mutation})

            await mutation
            const updatesCopy = {...activeUpdates}
            delete updatesCopy[chat.id]
            setActiveUpdates(updatesCopy)
        },
        isMutating: activeUpdates[chat.id]
    }))

    const rowActions = [
        {
            label: 'Update',
            func: () => {},
            type: 'submit',
            enabled: (row) => chatUpdates[row.id]
        },
        {
            label: 'Delete',
            func: async (row) => {
                const mutation = deleteNotificationsMutation.mutateAsync({chat: row})
                setActiveUpdates({...activeUpdates, [row.id]: mutation})

                await mutation
                const updatesCopy = {...activeUpdates}
                delete updatesCopy[row.id]
                setActiveUpdates(updatesCopy)
            },
        },
    ]

    return (
        <div className="radius-card pools-table-view">
            <div className="header">
                <div>
                    <h3 className="title sub">
                        Telegram Chats
                        <CircledQuestionIcon />
                    </h3>
                </div>
            </div>
            <div className="">
                {telegramDataQuery.data?.length == 0 ? (
                    <div className="title">
                        No chats registered. Use `/notifications` in a chat to enable notifications
                    </div>
                ) : (
                    <GridTable headers={chatsHeaders} rowActions={rowActions} rows={rows} />
                )}
            </div>
        </div>
    )
}
