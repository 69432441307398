export const numberFormatter = function (num, digits) {
    if (isNaN(num)) return "-";
    if (num === 0) return "0";
    var si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) break;
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const hasData = function (field) {
    return field !== undefined && field !== null && field !== {} && field.length !== 0;
}

export const hideable = function (vValue, isHidden) {
    return isHidden ? "*****" : vValue;
}

export const isMobile = function () {
    return document.documentElement.clientWidth <= 768;
}