export default function ArrowIcon({ width = 43, color = '#FEA902', arrowColor = 'white'}) {
    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="21.5" cy="21.5" r="21.5" fill={color} />
            <path
                d="M34.0607 22.0607C34.6464 21.4749 34.6464 20.5251 34.0607 19.9393L24.5147 10.3934C23.9289 9.80761 22.9792 9.80761 22.3934 10.3934C21.8076 10.9792 21.8076 11.9289 22.3934 12.5147L30.8787 21L22.3934 29.4853C21.8076 30.0711 21.8076 31.0208 22.3934 31.6066C22.9792 32.1924 23.9289 32.1924 24.5147 31.6066L34.0607 22.0607ZM9 22.5H33V19.5H9V22.5Z"
                fill={arrowColor}
            />
        </svg>
    );
}
