import React, {useEffect, useState} from "react";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import Loader from "../../components/loader/Loader";
import "./pools.css";
import "../../components/table/table.css";
import CircledQuestionIcon from "../../components/icon/CircledQuestionIcon";
import {firestore} from "../../firebase/firebase.utils";
import {useAuth} from "../../context/AuthContext";
import {hasData} from "../../components/utility/utilityFunctions";
import PoolsTableView from "../../components/pools-table-view/PoolsTableView";
import { useAPI } from "../../context/APIContext";

export default function Pools() {
    const [pools, setPools] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser } = useAuth();
    const apiClient = useAPI();

    useEffect(() => {
        setIsLoading(true);
        apiClient.get('/pools?sharedOnly=true').then((qSnapDataPools) => {
            setPools(qSnapDataPools.data.map(poolDoc => {
                const thisPoolData = poolDoc;
                delete thisPoolData.allowRead;
                thisPoolData.status = "processing"; // todo pool status
                return thisPoolData;
            }));
        }).finally(() => setIsLoading(false));
    }, [currentUser.uid]);

    return (
            <PageWrapper>
                { isLoading ? <Loader/> : null }
                        <div className="pools">
                            <div className="header">
                                <div>
                                    <h3 className="title">
                                        Pools<CircledQuestionIcon text="Pool information for pools that you have access to."/>
                                    </h3>
                                </div>
                            </div>
                            { hasData(pools) ? <PoolsTableView pools={pools}/> : <h5>There are no pools with access granted to you.</h5> }
                        </div>
            </PageWrapper>
    );
}
