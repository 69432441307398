import { ResponsiveLine } from "@nivo/line";
import {numberFormatter} from "../utility/utilityFunctions";

export const DelegationLineGraph = ({ data, tickValues, minTick }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 20, right: 20, bottom: 15, left: 20 }}
    colors={["#3178c9"]}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: false,
      reverse: false,
    }}
    axisTop={null}
    axisRight={null}
    axisLeft={null}
    axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 3
    }}
    enableGridX={false}
    gridYValues={tickValues}
    isInteractive={false}
    enablePointLabel={true}
    pointLabel={d => numberFormatter(d.y, 2)}
    pointLabelYOffset={-10}
    xScale={{ type: "point" }}
    enablePoints={true}
    pointSize={6}
    pointColor="#ffffff"
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    enableArea={true}
    areaBaselineValue={minTick}
    areaBlendMode="darken"
    enableCrosshair={false}
    useMesh={true}
    animate={true}
  />
);
