export default function Dropdown({options = [], onChange, value}) {
    return (
        <div>
            {options.length > 1
                ? <select value={value} style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={onChange}>
                    {options.map((option) => (<option value={option.value} key={option.value}>{option.label}</option>))}
                </select>
                : <select disabled style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={onChange}>
                    <option value={options[0]?.value} key={options[0]?.value}>{options[0]?.label}</option>
                </select>}
        </div>
    )

}