import React from 'react';
import { useAuth } from '../../context/AuthContext';
import "./side-nav.css"
import {useHistory} from "react-router-dom";
import StarNavItem from "../star-nav-item/StarNavItem";

export default function SideNav( { show } ) {
  const { currentUser, logout, isAdmin, poolCount } = useAuth();
  const history = useHistory();
  
  function isPath(expected) {
    return history.location.pathname === expected;
  }

  function pathStarts(expected) {
    return history.location.pathname.startsWith(expected);
  }
  
  return (
    <div className={(show ? "" : "sm-hidden ") + "side-nav"}>
      <img className="logo" src="/img/logo.png" alt="Stakepool 24/7" />
      <div className="nav-section">
        <StarNavItem className="lg-hidden" imgSrc={currentUser?.photoURL} bg="none" />
      </div>
      <div className="nav-section">
        <h3 className="section-title">Main Menu</h3>
        <div className={(isPath("/statistics") ? "active " : "") + "nav-item"} onClick={() => history.push("/statistics")}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16.5448 7.3956C16.5444 7.39521 16.5441 7.39482 16.5437 7.39443L9.60768 0.458706C9.31204 0.162934 8.91897 0 8.50087 0C8.08277 0 7.6897 0.162804 7.39393 0.458577L0.461578 7.3908C0.459243 7.39314 0.456907 7.3956 0.454572 7.39794C-0.152539 8.00855 -0.151501 8.99926 0.457556 9.60831C0.735815 9.8867 1.10333 10.0479 1.49626 10.0648C1.51222 10.0664 1.5283 10.0671 1.54452 10.0671H1.82096V15.1714C1.82096 16.1815 2.64277 17.0033 3.65306 17.0033H6.36664C6.64166 17.0033 6.86478 16.7803 6.86478 16.5051V12.5034C6.86478 12.0425 7.23969 11.6676 7.7006 11.6676H9.30114C9.76205 11.6676 10.137 12.0425 10.137 12.5034V16.5051C10.137 16.7803 10.36 17.0033 10.6351 17.0033H13.3487C14.359 17.0033 15.1808 16.1815 15.1808 15.1714V10.0671H15.4371C15.8551 10.0671 16.2482 9.90434 16.5441 9.60857C17.1538 8.99848 17.154 8.00608 16.5448 7.3956Z" />
          </svg>
          Statistics
        </div>
        {/* <div className={(isPath("/pools") ? "active " : "") + "nav-item disabled"} onClick={() => history.push("/pools")}>
          <svg
            width="14"
            height="19"
            viewBox="0 0 14 19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.5033 0H0.821384C0.367745 0 0 0.367745 0 0.821384V17.5098C0 17.9635 0.367745 18.3312 0.821384 18.3312H1.65579C2.10943 18.3312 2.47718 17.9635 2.47718 17.5098C2.47718 15.2021 4.35463 13.3247 6.66232 13.3247C8.97002 13.3247 10.8475 15.2021 10.8475 17.5098C10.8475 17.9635 11.2152 18.3312 11.6689 18.3312H12.5033C12.9569 18.3312 13.3247 17.9635 13.3247 17.5098V0.821384C13.3247 0.367745 12.9569 0 12.5033 0ZM4.99351 3.33767H8.33122C8.78486 3.33767 9.15261 3.70542 9.15261 4.15906C9.15261 4.6127 8.78486 4.98044 8.33122 4.98044H4.99351C4.53987 4.98044 4.17212 4.6127 4.17212 4.15906C4.17212 3.70542 4.53987 3.33767 4.99351 3.33767ZM9.16563 8.31816H4.1591C3.70546 8.31816 3.33771 7.95041 3.33771 7.49677C3.33771 7.04313 3.70546 6.67539 4.1591 6.67539H9.16563C9.61927 6.67539 9.98701 7.04313 9.98701 7.49677C9.98701 7.95041 9.61923 8.31816 9.16563 8.31816Z" />
          </svg>
          Pool Data
        </div> */}
        {(poolCount > 0 || isAdmin) && (
          <div className={(isPath("/pools/monitor") ? "active " : "") + "nav-item"} onClick={() => history.push("/pools/monitor")}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19.4147 18.828H18.8239V5.29248C18.8239 4.96887 18.5615 4.70652 18.2379 4.70652H15.8843C15.5607 4.70652 15.2984 4.96887 15.2984 5.29248V18.828H14.1167V7.64606C14.1167 7.32246 13.8543 7.06011 13.5307 7.06011H11.1771C10.8535 7.06011 10.5912 7.32246 10.5912 7.64606V18.828H9.40952V9.99965C9.40952 9.67604 9.14716 9.41369 8.82356 9.41369H6.46998C6.14637 9.41369 5.88402 9.67604 5.88402 9.99965V18.828H4.70235V12.3532C4.70235 12.0296 4.44 11.7673 4.11639 11.7673H1.76281C1.4392 11.7673 1.17685 12.0296 1.17685 12.3532V18.828H0.586016C0.262412 18.828 6.10352e-05 19.0904 6.10352e-05 19.414C6.10352e-05 19.7376 0.262412 19.9999 0.586016 19.9999H19.4147C19.7383 19.9999 20.0006 19.7376 20.0006 19.414C20.0006 19.0904 19.7383 18.828 19.4147 18.828Z" />
              <path d="M0.585286 8.23272C0.604545 8.23272 0.624037 8.23178 0.643608 8.22983C3.6371 7.93396 6.44301 7.37285 8.98332 6.56196C11.0238 5.91065 12.8994 5.09739 14.558 4.1447C15.9685 3.3346 16.9815 2.56231 17.6519 1.98041V2.93955C17.6519 3.26315 17.9143 3.5255 18.2379 3.5255C18.5615 3.5255 18.8238 3.26315 18.8238 2.93955V0.585956C18.8238 0.262352 18.5615 0 18.2379 0H15.8843C15.5607 0 15.2983 0.262352 15.2983 0.585956C15.2983 0.90956 15.5607 1.17191 15.8843 1.17191H16.792C16.1604 1.71048 15.2128 2.42293 13.9071 3.16693C11.4138 4.5878 7.06873 6.41723 0.52837 7.06358C0.206329 7.09542 -0.0289517 7.38226 0.00288528 7.7043C0.032769 8.00681 0.287582 8.23272 0.585286 8.23272Z" />
            </svg>
            Monitoring
          </div>
        )}
      </div>
      <div className="nav-section">
        <h3 className="section-title">Monitoring</h3>
        <div className={(isPath("/pools") ? "active " : "") + "nav-item"} onClick={() => history.push("/pools")}>
          <div className="left">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.06745 0C4.05452 0 2.4169 1.73633 2.4169 3.87055C2.4169 6.00477 4.05452 7.74109 6.06745 7.74109C8.08038 7.74109 9.71803 6.00477 9.71803 3.87055C9.71803 1.73633 8.08042 0 6.06745 0Z" />
              <path d="M14.3671 0.553867C14.3589 0.553867 14.3505 0.553906 14.3423 0.553945C13.4975 0.560938 12.7084 0.917617 12.1205 1.55836C11.5428 2.18777 11.2287 3.01813 11.2359 3.89641C11.2432 4.77469 11.571 5.59973 12.1589 6.21957C12.7515 6.84422 13.5364 7.18727 14.3718 7.18727C14.3802 7.18727 14.3886 7.18723 14.3971 7.18719C15.2418 7.18019 16.0309 6.82348 16.6189 6.18277C17.1965 5.55336 17.5106 4.72301 17.5034 3.84469C17.4884 2.02461 16.0839 0.553867 14.3671 0.553867Z" />
              <path d="M14.5211 7.82094H14.218C12.8063 7.82094 11.518 8.35766 10.5453 9.23734C10.7887 9.42406 11.0203 9.62758 11.2398 9.84711C11.8875 10.4948 12.3961 11.2495 12.7516 12.0901C13.0719 12.8471 13.2547 13.6448 13.2973 14.4662H20V13.2995C20 10.2787 17.5422 7.82094 14.5211 7.82094Z" />
              <path d="M12.123 14.4662C12.0125 12.6854 11.1098 11.119 9.7625 10.1151C8.78242 9.38461 7.5668 8.95219 6.25 8.95219H5.88516C2.63477 8.95223 0 11.587 0 14.8373V15.5358H12.1348V14.8373C12.1348 14.7127 12.1309 14.5889 12.123 14.4662Z" />
            </svg>
            Pool information
          </div>
        </div>
        <div className={(pathStarts("/pool-registration") ? "active " : "") + "nav-item"} onClick={() => history.push("/pool-registration")}>
          <div className="left">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.2084 1.66667H9.29171C9.01671 0.708333 8.13337 0 7.08337 0C6.03337 0 5.15004 0.708333 4.87504 1.66667H3.95837C3.61671 1.66667 3.33337 1.95 3.33337 2.29167V3.54167C3.33337 4.34167 3.99171 5 4.79171 5H9.37504C10.175 5 10.8334 4.34167 10.8334 3.54167V2.29167C10.8334 1.95 10.55 1.66667 10.2084 1.66667Z" />
              <path d="M11.875 2.5H11.6667V3.54167C11.6667 4.80833 10.6417 5.83333 9.375 5.83333H4.79167C3.525 5.83333 2.5 4.80833 2.5 3.54167V2.5H2.29167C1.025 2.5 0 3.525 0 4.79167V15.2083C0 16.475 1.025 17.5 2.29167 17.5H8.44167L8.625 16.475C8.70833 16.0083 8.925 15.5917 9.25833 15.25L9.925 14.5833H3.125C2.78333 14.5833 2.5 14.3 2.5 13.9583C2.5 13.6167 2.78333 13.3333 3.125 13.3333H11.0417C11.0833 13.3333 11.1167 13.3333 11.1583 13.35H11.1667L14.1667 10.35V4.79167C14.1667 3.525 13.1417 2.5 11.875 2.5ZM11.0417 11.875H3.125C2.78333 11.875 2.5 11.5917 2.5 11.25C2.5 10.9083 2.78333 10.625 3.125 10.625H11.0417C11.3833 10.625 11.6667 10.9083 11.6667 11.25C11.6667 11.5917 11.3833 11.875 11.0417 11.875ZM11.0417 9.16666H3.125C2.78333 9.16666 2.5 8.88333 2.5 8.54166C2.5 8.2 2.78333 7.91666 3.125 7.91666H11.0417C11.3833 7.91666 11.6667 8.2 11.6667 8.54166C11.6667 8.88333 11.3833 9.16666 11.0417 9.16666Z" />
              <path d="M10.4392 20C10.275 20 10.115 19.935 9.99752 19.8167C9.85336 19.6725 9.78836 19.4675 9.82419 19.2658L10.2659 16.7617C10.2875 16.6358 10.3492 16.5192 10.4392 16.4283L16.6267 10.2417C17.3867 9.48 18.1334 9.68583 18.5417 10.0942L19.5725 11.125C20.1417 11.6933 20.1417 12.6183 19.5725 13.1875L13.385 19.375C13.295 19.4658 13.1784 19.5267 13.0517 19.5483L10.5475 19.99C10.5117 19.9967 10.475 20 10.4392 20ZM12.9434 18.9333H12.9517H12.9434Z" />
            </svg>
            New Pool registration
          </div>
        </div>
        {(poolCount > 0 || isAdmin) && (
          <>
            <div className={(isPath("/triggers") ? "active " : "") + "nav-item"} onClick={() => history.push("/triggers")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
              </svg>
              Alert Triggers
            </div>
            <div className={(isPath("/alerts") ? "active " : "") + "nav-item"} onClick={() => history.push("/alerts")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
              </svg>
              Alerts Config
            </div>
          </>
        )}
      </div>
      { true ?
        <div className="nav-section">
          <h3 className="section-title">Administration</h3>
            <div className={(isPath("/admin") ? "active " : "") + "nav-item"} onClick={() => history.push("/admin")}>
              <div className="left">
                <svg
                        width="20"
                        height="16"
                        viewBox="0 0 20 16"
                        xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6.06745 0C4.05452 0 2.4169 1.73633 2.4169 3.87055C2.4169 6.00477 4.05452 7.74109 6.06745 7.74109C8.08038 7.74109 9.71803 6.00477 9.71803 3.87055C9.71803 1.73633 8.08042 0 6.06745 0Z" />
                  <path d="M14.3671 0.553867C14.3589 0.553867 14.3505 0.553906 14.3423 0.553945C13.4975 0.560938 12.7084 0.917617 12.1205 1.55836C11.5428 2.18777 11.2287 3.01813 11.2359 3.89641C11.2432 4.77469 11.571 5.59973 12.1589 6.21957C12.7515 6.84422 13.5364 7.18727 14.3718 7.18727C14.3802 7.18727 14.3886 7.18723 14.3971 7.18719C15.2418 7.18019 16.0309 6.82348 16.6189 6.18277C17.1965 5.55336 17.5106 4.72301 17.5034 3.84469C17.4884 2.02461 16.0839 0.553867 14.3671 0.553867Z" />
                  <path d="M14.5211 7.82094H14.218C12.8063 7.82094 11.518 8.35766 10.5453 9.23734C10.7887 9.42406 11.0203 9.62758 11.2398 9.84711C11.8875 10.4948 12.3961 11.2495 12.7516 12.0901C13.0719 12.8471 13.2547 13.6448 13.2973 14.4662H20V13.2995C20 10.2787 17.5422 7.82094 14.5211 7.82094Z" />
                  <path d="M12.123 14.4662C12.0125 12.6854 11.1098 11.119 9.7625 10.1151C8.78242 9.38461 7.5668 8.95219 6.25 8.95219H5.88516C2.63477 8.95223 0 11.587 0 14.8373V15.5358H12.1348V14.8373C12.1348 14.7127 12.1309 14.5889 12.123 14.4662Z" />
                </svg>
                User Management
              </div>
            </div>
        </div>
      : null }
      <div className="nav-section">
        <h3 className="section-title">Settings</h3>
        <div className={(isPath("/profile") ? "active " : "") + "nav-item"} onClick={() => history.push("/profile")}>
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.78906 0C5.88129 0 3.51562 2.36566 3.51562 5.27344C3.51562 8.18121 5.88129 10.5469 8.78906 10.5469C11.6968 10.5469 14.0625 8.18121 14.0625 5.27344C14.0625 2.36566 11.6968 0 8.78906 0Z" />
            <path d="M15.3502 13.992C13.9065 12.5261 11.9925 11.7188 9.96094 11.7188H7.61719C5.58563 11.7188 3.67164 12.5261 2.22789 13.992C0.791211 15.4507 0 17.3763 0 19.4141C0 19.7377 0.262344 20 0.585938 20H16.9922C17.3158 20 17.5781 19.7377 17.5781 19.4141C17.5781 17.3763 16.7869 15.4507 15.3502 13.992Z" />
          </svg>
          Account
        </div>
        {/* <div className={(isPath("/faq") ? "active " : "") + "nav-item disabled"} onClick={() => history.push("/faq")}>
          <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18.2353 2.01188H10.1137L8.49608 0.0883548C8.4478 0.0303156 8.37544 -0.00223361 8.3 0.000119331H1.76471C0.786472 0.00982522 -0.00112624 0.806149 1.20895e-06 1.78443V13.5491C-4.78107e-05 14.5266 0.787256 15.3218 1.76471 15.3315H18.2353C19.2127 15.3218 20 14.5266 20 13.5491V3.79424C20 2.81674 19.2127 2.02159 18.2353 2.01188Z" />
          </svg>
          FAQ
        </div> */}
        <div className={(isPath("/contact-us") ? "active " : "") + "nav-item"} onClick={() => history.push("/contact-us")}>
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 16 16"
          >
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
          </svg>
          Contact Us
        </div>
        {/* <div className={(isPath("/settings") ? "active " : "") + "nav-item disabled"} onClick={() => history.push("/settings")}>
          <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
          >
            <path
                    d="M20 10C20 9.2562 19.464 8.46322 18.8033 8.22934C18.1426 7.99545 17.5021 7.42273 17.3157 6.97107C17.1293 6.51942 17.1777 5.65992 17.4793 5.02686C17.7806 4.3938 17.5979 3.45372 17.0719 2.92769C16.5463 2.40165 15.6062 2.21942 14.9727 2.52066C14.3397 2.8219 13.4814 2.87107 13.031 2.68471C12.5806 2.49835 12.0074 1.85702 11.7727 1.19628C11.5376 0.535537 10.7438 0 10 0C9.2562 0 8.4624 0.535537 8.22727 1.19628C7.99215 1.85702 7.41942 2.49835 6.96901 2.68471C6.51859 2.87107 5.65992 2.8219 5.02686 2.52066C4.3938 2.21942 3.45372 2.40165 2.92769 2.92769C2.40165 3.45372 2.21942 4.39339 2.52066 5.02686C2.8219 5.66033 2.86983 6.51901 2.68264 6.97025C2.49545 7.42149 1.85702 7.99545 1.19628 8.22934C0.535537 8.46322 0 9.2562 0 10C0 10.7438 0.535537 11.5376 1.19628 11.7727C1.85702 12.0074 2.49628 12.5814 2.68347 13.0318C2.87066 13.4818 2.8219 14.3401 2.52066 14.9731C2.21942 15.6062 2.40165 16.5463 2.92769 17.0723C3.45372 17.5979 4.39339 17.7806 5.02686 17.4793C5.66033 17.1781 6.51859 17.1293 6.96901 17.3161C7.41983 17.5029 7.99215 18.1459 8.22727 18.8054C8.4624 19.4649 9.2562 20 10 20C10.7438 20 11.5376 19.4649 11.7727 18.8054C12.0074 18.1459 12.5798 17.5037 13.0302 17.3161C13.4806 17.1289 14.3401 17.1781 14.9727 17.4797C15.6062 17.781 16.5463 17.5983 17.0719 17.0727C17.5979 16.5467 17.7806 15.6066 17.4793 14.9736C17.1781 14.3401 17.1285 13.4818 17.3149 13.0314C17.5012 12.581 18.143 12.0079 18.8037 11.7731C19.4645 11.5376 20 10.7438 20 10ZM10 13.6694C7.97107 13.6694 6.33058 12.0289 6.33058 10.0041C6.33058 7.97521 7.97107 6.33471 10 6.33471C12.0244 6.33471 13.6653 7.97521 13.6653 10.0041C13.6653 12.0289 12.0248 13.6694 10 13.6694Z"
                    fillOpacity="0.7"
            />
          </svg>
          Settings
        </div> */}
        <div className="nav-item" onClick={() => { logout(); history.push("/login");}}>
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.5 10.8333C12.0392 10.8333 11.6667 11.2067 11.6667 11.6667V15C11.6667 15.4592 11.2933 15.8333 10.8333 15.8333H8.33333V3.33333C8.33333 2.62167 7.88 1.98583 7.19833 1.74917L6.95167 1.66667H10.8333C11.2933 1.66667 11.6667 2.04083 11.6667 2.5V5C11.6667 5.46 12.0392 5.83333 12.5 5.83333C12.9608 5.83333 13.3333 5.46 13.3333 5V2.5C13.3333 1.12167 12.2117 0 10.8333 0H1.875C1.84333 0 1.81667 0.0141667 1.78583 0.0183333C1.74583 0.015 1.7075 0 1.66667 0C0.7475 0 0 0.7475 0 1.66667V16.6667C0 17.3783 0.453333 18.0142 1.135 18.2508L6.15 19.9225C6.32 19.975 6.48917 20 6.66667 20C7.58583 20 8.33333 19.2525 8.33333 18.3333V17.5H10.8333C12.2117 17.5 13.3333 16.3783 13.3333 15V11.6667C13.3333 11.2067 12.9608 10.8333 12.5 10.8333Z" />
            <path d="M18.5937 7.92935L15.6501 5.64373C15.4396 5.4803 15.1232 5.43116 14.8479 5.51973C14.5734 5.6083 14.3939 5.81686 14.3939 6.04771V7.76193H11.4502C11.044 7.76193 10.7143 8.01792 10.7143 8.33334C10.7143 8.64876 11.044 8.90475 11.4502 8.90475H14.3939V10.619C14.3939 10.8498 14.5734 11.0584 14.8479 11.1469C15.1232 11.2355 15.4396 11.1864 15.6501 11.023L18.5937 8.73732C18.8815 8.5139 18.8815 8.15277 18.5937 7.92935Z" />
          </svg>
          Logout
        </div>
      </div>
    </div>
  );
}
