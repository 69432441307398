import axios from "axios";
import { createContext, useContext, useMemo } from "react";
import { useAuth } from "./AuthContext";

const APIContext = createContext();

export const useAPI = () => useContext(APIContext);

export const APIProvider = ({
  config = { baseURL: process.env.REACT_APP_API_URL || 'https://api.stakepool247.io:4000/' },
  children,
}) => {
  const { currentUser } = useAuth();

  // Using useMemo instead of useEffect to have this called before useEffect hooks from child components
  const axiosInstance = useMemo(() => {
    const ax = axios.create(config)
    ax.interceptors.request.use(
        async (req) => {
          if (currentUser) {
            req.headers["Authorization"] = `Bearer ${await currentUser.getIdToken()}`;
          }
          return req;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      return ax
  }, [config, currentUser])

  return (
    <APIContext.Provider value={axiosInstance}>
      {children}
    </APIContext.Provider>
  );
};
