import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth } from '../firebase/firebase.utils';
import {hasData} from "../components/utility/utilityFunctions";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [poolCount, setPoolCount] = useState(0);
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function confirmPasswordReset(code, password) {
    return auth.confirmPasswordReset(code, password);
  }

  function updateProfile(profile) {
    return auth.currentUser.updateProfile(profile);
  }

  function applyActionCode(code) {
    return auth.applyActionCode(code);
  }

  function verifyEmail() {
    return currentUser.sendEmailVerification();
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAdminPermissions(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  function setAdminPermissions(user) {
    if (!hasData(user)) {
      setIsAdmin(false);
      setPoolCount(0);
      return;
    }
    user.getIdTokenResult().then((idTokenResult) => {
      // Confirm the user is an Admin.
      !!idTokenResult.claims.admin ? setIsAdmin(true) : setIsAdmin(false);
      !!idTokenResult.claims.poolCount ? setPoolCount(idTokenResult.claims.poolCount) : setPoolCount(0);
      console.log('idTokenResult.claims', idTokenResult.claims);
    });
  }

  const value = {
    currentUser,
    isAdmin,
    poolCount,
    login,
    signup,
    verifyEmail,
    applyActionCode,
    logout,
    resetPassword,
    confirmPasswordReset,
    updateProfile,
    updateEmail,
    updatePassword,
  };

    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
}
