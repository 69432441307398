import React from 'react';
import "./localized-loader.css";

export default function LocalizedLoader() {
  return (
    <div className="localized-loader-container">
      <div className="localized-loader">Loading...</div>
    </div>
  )
}
