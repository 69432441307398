import React, {useEffect, useMemo, useState} from "react";
import {useNumberFormatter} from "../../hooks/number-formatter";
import RightArowIcon from "../icon/RightArowIcon";
import {DelegationLineGraph} from "./DelegationLineGraph";
import {hasData, numberFormatter} from "../utility/utilityFunctions";
import RadiusCard from "../card/RadiusCard";
import LocalizedLoader from "../localized-loader/LocalizedLoader";

export default function DelegationGraphContainer({ loading = false, poolData = {}, epochData = [], currentPool = {}, currentEpoch }) {
  const [graphData, setGraphData] = useState([]);
  const [tickValues, setTickValues] = useState([]);
  const [minTick, setMinTick] = useState(0);
  const [delegationsLink, setDelegationsLink] = useState(null);
  const [minGraphWidth, setMinGraphWidth] = useState("0px");
  const currentEpochData = useMemo(() => epochData.find(({epoch}) => epoch == currentEpoch), [epochData, currentEpoch])
  const activeDelegation = useMemo(() => currentEpochData?.activeStake  / 1000000, [currentEpochData])
  const displayedActiveDelegation = useNumberFormatter(activeDelegation, 2)
  
  useEffect(() => {
    if (!hasData(epochData) || !hasData(currentEpoch)) return;
    const lineData = epochData
    .filter(({epoch}) => epoch <= currentEpoch)
    .reverse()
    .slice(-15)
    .map((epoch) => {
      return {
        x: epoch.epoch,
        y: epoch.activeStake / 1000000,
        formatted: numberFormatter(epoch.activeStake / 1000000, 2)
      };
    });
    setTickValues(calculateTickValues(lineData.map((point) => Math.floor(point.y))));
    setDelegationsLink(buildDelegationsLink(currentEpochData?.poolId));
    const data = [
      {
        id: "Pool",
        data: lineData,
      },
    ];
    setGraphData(data);
    if (hasData(lineData)) {
      setMinGraphWidth(lineData.length * 41 + "px");
    }
  }, [currentEpoch, currentPool, epochData, poolData]);

  const calculateTickValues = function (values) {
    if (!hasData(values)) return [0];
    if (values.length === 1) return [...values];
    const ticks = [];
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
    const maxTick = Math.ceil(maxValue/100) * 100;
    const minTick = Math.floor(minValue/100) * 100;
    setMinTick(minTick);
    let step = (maxTick-minTick) / 4;
    step = step <= 0 ? 1 : step;
    for (let i = minTick; i <= maxTick; i+=step) {
      ticks.push(i);
    }
    return ticks;
  }
  
  function buildDelegationsLink(poolId) {
    return poolId ? "https://cardanoscan.io/delegations?poolId=".concat(poolId) : null;
  }

  return (
    <RadiusCard className="w-1-2 sm-w-1-1">
        <div className="header">
        <div>
          <span className="title sub">{displayedActiveDelegation}</span>
        </div>
        <div>
          <span className="">Active delegation</span>
        </div>
      </div>
      <div className="horizontal-scroll scroll-rigth pv-5" id="delegationGraph">
        <div style={{ height: "140px", minWidth: minGraphWidth, position: 'relative'}}>
            <DelegationLineGraph data={graphData} tickValues={tickValues} minTick={minTick}/>
            {graphData?.[0]?.data && !loading ? null : <LocalizedLoader />}
        </div>
      </div>
      <hr />
      <div className="bottom">
        <a href={delegationsLink} rel="noreferrer" target="_blank"><RightArowIcon /></a>
        <a className="text" href={delegationsLink} rel="noreferrer" target="_blank">
          See all delegators
        </a>
      </div>
    </RadiusCard>
  );
}
