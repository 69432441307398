import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { ResponsiveBullet } from '@nivo/bullet'
import { ResponsiveBar } from '@nivo/bar'
import { useMemo } from 'react'
import './server-status.css'

export default function ServerStatus({ name = '', metrics = {} }) {
    // const userCPU = 75
    const usedCPU = useMemo(() => {
        const cpuMetrics = metrics['cpu_load'] || []
        cpuMetrics.sort((a, b) => a.data.bucket < b.data.bucket)
        return cpuMetrics?.[0]?.data?.metric_value_avg?.toFixed(2)
    }, [metrics])

    const memoryFree = useMemo(() => {
        const memoryFreeMetrics = metrics['mem_avail'] || []
        memoryFreeMetrics.sort((a, b) => a.data.bucket < b.data.bucket)
        return memoryFreeMetrics?.[0]?.data?.metric_value_avg?.toFixed(2)
    }, [metrics])

    const memoryTotal = useMemo(() => {
        const memoryTotalMetrics = metrics['mem_total'] || []
        memoryTotalMetrics.sort((a, b) => a.data.bucket < b.data.bucket)
        return memoryTotalMetrics?.[0]?.data?.metric_value_avg?.toFixed(2)
    }, [metrics])

    const hddFree = useMemo(() => {
        const hddFreeMetrics = metrics['hdd_free'] || []
        hddFreeMetrics.sort((a, b) => a.data.bucket < b.data.bucket)
        return hddFreeMetrics?.[0]?.data?.metric_value_avg?.toFixed(2)
    }, [metrics])

    const hddUsed = useMemo(() => (100 - hddFree).toFixed(2), [hddFree])

    const kesCount = useMemo(() => {
        const kesCountMetrics = metrics['kes_count'] || []
        kesCountMetrics.sort((a, b) => a.data.bucket < b.data.bucket)
        return kesCountMetrics?.[0]?.data?.metric_value_avg?.toFixed(0)
    }, [metrics])

    const CPUdata = [
        {
            id: 'Used CPU',
            data: [
                {
                    x: 'CPU',
                    y: usedCPU,
                },
            ],
        },
    ]

    const memoryData = [
        {
            id: 'Used RAM',
            data: [
                {
                    x: 'RAM',
                    y: memoryTotal - memoryFree,
                },
            ],
        },
    ]

    const bulletData = [
        {
            id: 'Used HDD',
            value: hddUsed,
        },
    ]

    const renderCPU = () => (
        <div className="server-status-gauge-container flex gap-4">
            <div className="h-20 w-20 flex">
                <ResponsiveRadialBar
                    data={CPUdata}
                    maxValue={100}
                    enableRadialGrid={false}
                    startAngle={-135}
                    endAngle={135}
                    radialAxisStart={false}
                    circularAxisOuter={false}
                    colors="#3178c9C1"
                    tracksColor="#e0e0e0"
                    innerRadius={0.5}
                    cornerRadius={100}
                />
            </div>
            <div className="flex flex-col w-20">
                <div className="server-status-metric-name">CPU</div>
                <div className="server-status-metric-value">{usedCPU}%</div>
            </div>
        </div>
    )

    const renderMem = () => (
        <div className="server-status-gauge-container flex gap-4">
            <div className="h-20 w-20 flex">
                <ResponsiveRadialBar
                    data={memoryData}
                    maxValue={memoryTotal}
                    enableRadialGrid={false}
                    startAngle={-135}
                    endAngle={135}
                    radialAxisStart={false}
                    circularAxisOuter={false}
                    colors="#3178c9C1"
                    tracksColor="#e0e0e0"
                    innerRadius={0.5}
                    cornerRadius={100}
                />
            </div>
            <div className="flex flex-col w-20">
                <div className="server-status-metric-name">Memory</div>
                <div className="server-status-metric-value">
                    {(((memoryTotal - memoryFree) / memoryTotal) * 100).toFixed(2)}%
                </div>
            </div>
        </div>
    )

    const renderHdd = () => (
        <div className="server-status-metric-container metric-wide">
            <div className="server-status-metric-name">Storage Usage</div>
            <div className="w-80 h-4 child-svg-round">
                <ResponsiveBar
                    theme={{
                        background: '#e0e0e0',
                    }}
                    data={bulletData}
                    indexBy={'id'}
                    keys={['value']}
                    maxValue={100}
                    layout="horizontal"
                    minValue={0}
                    colors={['#3178c9C1', '#e0e0e0']}
                    borderRadius={5.5}
                    enableLabel={false}
                    padding={0}
                    innerPadding={0}
                    enableGridY={false}
                />
            </div>
            <div className="server-status-metric-value">
                <div className="server-status-metric-percentage">{hddUsed}%</div>
                {/* <div className="server-status-metric-value">8.86 TB / 12.5 TB</div> */}
            </div>
        </div>
    )

    const renderKESCount = () => (
        <div className="server-status-metric-container metric-wide">
            <div
                className="w-20 h-20 rounded-full bg-white flex justify-center items-center"
                style={{ border: '6px solid #3178c9C1' }}
            >
                {kesCount}
            </div>
            <div className="server-status-metric-name">KES Count</div>
        </div>
    )

    return (
        <div className="server-status-data flex flex-col pt-4 px-4 justify-start items-start gap-4">
            <div className="server-status-name">{name}</div>
            <div className="flex gap-12 justify-start">
                {usedCPU ? renderCPU() : null}
                {memoryFree ? renderMem() : null}
                {kesCount ? renderKESCount() : null}
                {hddFree ? renderHdd() : null}
            </div>
        </div>
    )
}
