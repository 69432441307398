import React, {useEffect, useState} from 'react';
import RadiusCard from '../../components/card/RadiusCard';
import "../../components/request-notif-card/request-notif-card.css";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import Loader from "../../components/loader/Loader";
import {hasData} from "../../components/utility/utilityFunctions";

export default function VerifyConfirm({ textSuccess = {}, textFail = {}}) {
    const [ didVerify, setDidVerify ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [failReason, setFailReason] = useState(null);
    const { currentUser, applyActionCode } = useAuth();
    const history = useHistory();
    
    function verifyEmailFN(oobCode) {
        applyActionCode(oobCode).then((_) => {
            currentUser.reload().then(() => setDidVerify(true)).catch(() => {
                // console.error("Email verification failed. Reason: s%", error);
                setFailReason("There was an issue during verification. Please try refreshing the page.")
            }).finally(() => setIsLoading(false));
        }).catch(() => {
            // console.error("Email verification failed. Reason: s%", error);
            setFailReason("The link seems to be invalid or expired. Please request a new verification link")
            setIsLoading(false)
        });
    }

    function resetPasswordFN(oobCode) {
        history.push(`/reset-password?oobCode=${oobCode}`);
    }
    
    useEffect(() => {
        if (didVerify) return
        setIsLoading(true);
        
        let params = history.location.search;
        if (history.location.search.startsWith("?")) {
            params = params.substring(1);
        }
        params = params.split("&");

        const queryOptions = {
            MODE: "mode",
            OOB_CODE: "oobCode",
            API_KEY: "apiKey",
            LANG: "lang"
        };
        let mode;
        let oobCode;
        for (let i = 0; i < params.length; i++) {
            const pair = params[i].split("=");
            const key = pair[0];
            switch (key) {
                case queryOptions.MODE:
                    mode = pair[1];
                    break;
                case queryOptions.OOB_CODE:
                    oobCode = pair[1];
                    break;
                default: break; 
            }
        }

        const modeOptions = {
            "verifyEmail": (oobCode) => verifyEmailFN(oobCode),
            "resetPassword": (oobCode) => resetPasswordFN(oobCode),
        };
        if (hasData(modeOptions[mode])) {
            modeOptions[mode](oobCode);
        } else {
            setIsLoading(false);
        }
    }, [didVerify]);
    
    
    return (<>
            { isLoading ? <Loader/> : null }
            { didVerify 
            ? <RadiusCard className="request-notif-card" radius="30px">
                  <h1 className="heading">Your request has been approved</h1>
                  <h3 className="sub-heading">StakePool247.io</h3>
                  <p className="descriptive-text">{textSuccess}</p>
                  <span>You can now access your <button className="link" onClick={() => history.push("/profile")}>profile</button></span>
              </RadiusCard> 
              : <RadiusCard className="request-notif-card" radius="30px">
                  <h1 className="heading">There has been a problem processing your request</h1>
                  <h3 className="sub-heading">StakePool247.io</h3>
                  <p className="descriptive-text">{textFail}</p>
                  {failReason === null 
                          ? <span>Please try <button className="link" onClick={() => history.push("/login")}>logging in</button> or <button className="link" onClick={() => history.push("/contact-us")}>contact us for support</button></span> 
                          : <span>{failReason} or <button className="link" onClick={() => history.push("/contact-us")}>contact us for support</button></span>
                  }
              </RadiusCard> }
    </>);
}
