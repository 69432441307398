import React from "react";
import "./progress-bar.css";
import "../small-graph-card/small-graph-card.css";

export default function ProgressBar({current = 0, total = 0}) {
    return (
            <div className="data">
                <div className="bar-container">
                    <div className="bar">
                        <div className="progress striped" style={{width: (100 * current) / total + "%"}}/>
                        <div className="limit"/>
                    </div>
                </div>
            </div>
    );
}
