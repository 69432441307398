import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {firestore} from "../../firebase/firebase.utils";
import EnvelopeIcon from "../icon/EnvelopeIcon";
import UserIcon from "../icon/UserIcon";
import {EyeIcon, EyeSlashIcon} from "../icon/EyeIcon";
import "./sign-up.css";
import { useAPI } from "../../context/APIContext";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [errorText, setErrorText] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, signup, updateProfile, logout } = useAuth();
  const history = useHistory();
  const apiClient = useAPI()
  
  function inputEmail(e) {
    setEmail(e.target.value);
  }
  function inputPassword(e) {
    setPassword(e.target.value);
  }
  function inputDisplayName(e) {
    setDisplayName(e.target.value);
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (currentUser) await logout();
      setIsLoading(true);
      const user = await signup(email, password);
      await updateProfile({ displayName: displayName });
      registerUserInDB(user.user);
      setErrorText("");
      history.push("/sign-up/verify");
      setIsLoading(false);
    } catch(error) {
      setErrorText(error.message);
      setIsLoading(false);
    }
  }
  
  function registerUserInDB(user) {
    apiClient.post('/users', { email: user.email, displayName: user.displayName, uid: user.uid })
    // .catch(err => console.error("Failed to add new user email to DB table. err: %s", err));
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="input">
        <input type="text" placeholder="Display name (max 20 symbols)" maxLength={20} onInput={inputDisplayName}/>
        <UserIcon />
      </div>
      <div className="input">
        <input type="text" placeholder="Email" onInput={inputEmail}/>
        <EnvelopeIcon />
      </div>
      <div className="input">
        <input type={passwordHidden ? "password" : "text"} maxLength={30} placeholder="Password (max 30 symbols)" onInput={inputPassword}/>
        {passwordHidden ? (
          <div className="icon" onClick={() => setPasswordHidden(false)}>
            <EyeIcon />
          </div>
        ) : (
          <div className="icon" onClick={() => setPasswordHidden(true)}>
            <EyeSlashIcon />
          </div>
        )}
      </div>
        <p className="error small">{errorText}</p>
      <button type="submit" className="signin-btn" disabled={isLoading}>
        Sign up
      </button>
        <p className="text-center">Already have an Account?&nbsp;
            <button className="link"  onClick={(e) => history.push("/login")}>
                Sign in
            </button>
        </p>
    </form>
  );
}
