import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAPI } from "../../context/APIContext";
import CircledQuestionIcon from "../icon/CircledQuestionIcon";


export default function DevicesView() {
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory();
    const apiClient = useAPI();
    const [devices, setDevices] = useState([])

    useEffect(() => {
        loadDevices()
    }, [apiClient])

    const loadDevices = () => {
        setIsLoading(true)
        apiClient.get('/devices')
            .then((devices) => {
                /* eslint-disable-next-line */
                console.log(devices)
                setDevices(devices.data)
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
            })
    }

    const deleteDevice = (device, e)  => {
        e?.preventDefault()
        setIsLoading(true)
        apiClient.delete(`/devices/${device.pool}/${device.name}`)
            .then(() => {
                loadDevices()
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    return (
        <div className="radius-card pools-table-view">
            <div className="header">
                <div>
                    <h3 className="title sub">
                        Your devices<CircledQuestionIcon/>
                    </h3>
                </div>
                <div>
                   <button className="btn" onClick={() => history.push("/devices/add")}>Add new device</button>
                </div>
            </div>
            <div className="horizontal-scroll">
                <table>
                    <thead>
                    <tr>
                        <th>Device Name</th>
                        <th>Pool</th>
                        <th>Type</th>
                        <th>External IP</th>
                        <th>Token</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                        {devices.map((device) => (
                            <tr key={`${device.pool}-${device.name}`}>
                                <td>{device.name}</td>
                                <td>{device.ticker.toUpperCase()}</td>
                                <td>{device.deviceType}</td>
                                <td>{device.externalIp}</td>
                                <td style={{width: 200, overflowWrap: 'anywhere'}}>{device.token}</td>
                                <td>
                                    <Link to={`/devices/edit/${device.pool}/${device.name}`}>Edit</Link>
                                    <br />
                                    <a href="#" onClick={(e) => deleteDevice(device, e)}>Delete</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )

}