import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CircledQuestionIcon from "../../components/icon/CircledQuestionIcon";
import Loader from "../../components/loader/Loader";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import { useAPI } from "../../context/APIContext";
import { functions, operands, Parser } from "../../utils/expressionParser";
import ExpressionConstructor from "./expression-constructor/ExpressionConstructor";


export default function AlertsEdit() {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const apiClient = useAPI();
    const history = useHistory()

    const [allowedMetrics, setAllowedMetrics] = useState([])
    const [allDevices, setAllDevices] = useState([])
    const [availableDevices, setAvailableDevices] = useState([])
    const [availablePools, setAvailablePools] = useState([])
    const [alertName, setAlertName] = useState('')
    const [expression, setExpression] = useState('')
    const [enabled, setEnabled] = useState(true)
    const [level, setLevel] = useState('Warning')
    const [deviceName, setDeviceName] = useState('')
    const [poolId, setPoolId] = useState('')
    const [constructorOpen, setConstructorOpen] = useState(false)
    const expressionRef = useRef()

    useEffect(() => {
        apiClient.get('/metrics/fields').then(res => {
            setAllowedMetrics(res.data)
            console.log(res.data)
            setIsLoading(false);
        }).catch((err) => {
            console.error(err)
            setIsLoading(false);
        });
        apiClient.get('/pools').then(res => {
            setAvailablePools(res.data)
            setIsLoading(false);
        }).catch((err) => {
            console.error(err)
            setIsLoading(false);
        });
    }, [apiClient])

    useEffect(() => {
        if (availablePools?.length > 0 && !id) {
            setPoolId(availablePools[0].id)
        }
    }, [availablePools])

    useEffect(() => {
        setIsLoading(true);
        apiClient.get('/devices').then(res => {
            setAllDevices(res.data)
            if (res.data?.length > 0) {
                setDeviceName(res.data[0].name)
            }
            console.log(res.data)
            setIsLoading(false);
        }).catch((err) => {
            console.error(err)
            setIsLoading(false);
        });
    }, [poolId])

    useEffect(() => {
        if (!id) {
            return
        }
        apiClient.get(`/alerts/${id}`).then(res => {
            const data = res.data
            console.log(data)
            setAlertName(data.name)
            setExpression(data.expression)
            setLevel(data.level)
            setPoolId(data.pool)
            setDeviceName(data.deviceName)
            setEnabled(data.enabled)
            setIsLoading(false);
        }).catch((err) => {
            /* eslint no-console: off */
            console.error(err)
            setIsLoading(false);
         });
    }, [apiClient, id])

    useEffect(() => {
        setAvailableDevices(allDevices.filter(d => d.pool === poolId))
    }, [poolId, allDevices])

    const appendFromConstructor = (newExpression) => {
        if (expression) {
            setExpression(`${expression}
or
${newExpression}`)
        } else {
            setExpression(newExpression)
        }
        setConstructorOpen(false)
    }

    const validateExpression = () => {
        try {
            const parser = new Parser(expression, allowedMetrics.map(m => m.value))
            const parsed = parser.Parse()
            const expr = parsed.QueryVars()
            console.log(expr)
            return true
        } catch (e) {
            console.error(e)
            setError(e.message)
            if (e.token) {
                expressionRef.current?.focus()
                expressionRef.current?.setSelectionRange(e.token.index, e.token.index + e.token.text.length);
            }
            return false
        }
    }

    const submitForm = (event) => {
        event.preventDefault();
        if (!validateExpression()) {
            return
        }
        setIsLoading(true);
        apiClient
            .post("/alerts", { expression: expression, name: alertName, id, enabled, level, deviceName, pool: poolId })
            .then(() => {
                setIsLoading(false);
                history.push("/alerts");
            })
            .catch((error) => {
                setError(error.message);
                setIsLoading(false);
            });
    }

    return (
        <PageWrapper>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="admin-panel">
                    <div className="header">
                        <div>
                            <span className="title">
                                Alert rules
                                <CircledQuestionIcon />
                            </span>
                        </div>
                    </div>
                    <div className="radius-card">
                        <form onSubmit={(e) => submitForm(e)}>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Alert name:</label>
                                        <input
                                            type="text"
                                            required
                                            value={alertName}
                                            onChange={(e) => 
                                                setAlertName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Expression:</label>
                                        <textarea
                                            type="text"
                                            ref={expressionRef}
                                            required
                                            rows={3}
                                            cols={50}
                                            value={expression}
                                            onChange={(e) => {
                                                setError('')
                                                setExpression(e.target.value)
                                            }}
                                        />
                                        <button type="button" className="btn m-10" onClick={() => setConstructorOpen(true)}>Add Expression</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Severity:</label>
                                        <select name="severity" value={level} onChange={(e) => 
                                                setLevel(e.target.value)
                                            }>
                                            <option value="Info">Info</option>
                                            <option value="Warning">Warning</option>
                                            <option value="Average">Average</option>
                                            <option value="High">High</option>
                                            <option value="Disaster">Disaster</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Pool:</label>
                                        <select name="pool" required value={poolId} onChange={(e) => 
                                                setPoolId(e.target.value)
                                            }>
                                            {availablePools.map(pool => (
                                                <option key={pool.id} value={pool.id}>{pool.ticker}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Device:</label>
                                        <select name="device" required value={deviceName} onChange={(e) => 
                                                setDeviceName(e.target.value)
                                            }>
                                            {availableDevices.map(dev => (
                                                <option key={dev.name} value={dev.name}>{dev.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Enabled:</label>
                                        <input
                                            type="checkbox"
                                            checked={enabled}
                                            onChange={(e) => {
                                                setEnabled(e.target.checked)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {error ? (
                                <div className="row wrap">
                                    {error}
                                </div>
                            ) : null}
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <button className="btn" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <ExpressionConstructor isOpen={constructorOpen} availableFields={allowedMetrics} availableOps={operands} availableFunctions={functions} onSubmit={appendFromConstructor} onClose={() => setConstructorOpen(false)} />
        </PageWrapper>
    );
}
