import React, {useEffect} from 'react';
import RadiusCard from '../../components/card/RadiusCard';
import "../../components/request-notif-card/request-notif-card.css";
import {useAuth} from "../../context/AuthContext";
import {useHistory} from "react-router-dom";

export default function VerifyEmail({text = {}}) {
    const {verifyEmail, currentUser} = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (currentUser) {
            if (currentUser.emailVerified) {
                history.push("/statistics");
            } else {
                verifyEmail();
            }
        }
    }, [currentUser?.uid]);

    async function sendVerificationLink() {
        if (currentUser) {
            verifyEmail();
        } else {
            history.push("/login");
        }
    }

    return (
            <RadiusCard className="request-notif-card" radius="30px">
                <h1 className="heading">Your request has been submitted</h1>
                <h3 className="sub-heading">StakePool247.io</h3>
                <p className="descriptive-text">Please check your email.</p>
                <p className="descriptive-text">{text}</p>
                <span>Did not receive an email? </span>
                <button className="link" onClick={() => sendVerificationLink()}>Resend link or login</button>
            </RadiusCard>
    );
}
