import moment from 'moment'
import React, { useEffect, useState } from 'react'

const timeRangeStartTime = {
    'custom': moment().subtract(1, 'hours'),
    '5 minutes': moment().subtract(5, 'minutes'),
    '30 minutes': moment().subtract(30, 'minutes'),
    '1 hour': moment().subtract(1, 'hours'),
    '6 hours': moment().subtract(6, 'hours'),
    '12 hours': moment().subtract(12, 'hours'),
    '1 day': moment().subtract(1, 'days'),
    '2 days': moment().subtract(2, 'days'),
    '7 days': moment().subtract(7, 'days'),
    '30 days': moment().subtract(30, 'days'),
    '3 months': moment().subtract(3, 'months'),
    '6 months': moment().subtract(6, 'months'),
    '1 year': moment().subtract(1, 'years')
}

export default function DateRangeSelect({ startTime, endTime, onSetStart, onSetEnd }) {

    const [timeRange, setTimeRange] = useState('custom')

    const handleRangeChange = (e) => {
        const selectedRange = e.target.value
        setTimeRange(selectedRange)
    }

    useEffect(() => {
        console.log('New time range', timeRange)
        onSetStart(timeRangeStartTime[timeRange])
    }, [timeRange])

    return (<>
        <div className="filter">
            <select value={timeRange} style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={handleRangeChange}>
                {Object.entries(timeRangeStartTime).map(([range, startTime]) => (<option value={range} key={range}>{range === 'custom' ? 'Custom' : `Last ${range}`}</option>))}
            </select>
        </div>
    </>)

}