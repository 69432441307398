import './pool-registration.css'
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import { useState } from 'react';
import ActionSelect from './steps/ActionSelect';
import PrivacyPolicy from './steps/PrivacyPolicy';
import AuthMethodSelect from './steps/AuthMethodSelect';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import MetadataAuthentication from './steps/MetadataAuthentication';

export default function PoolRegistration() {
    const [step, setStep] = useState('action-select')

    return (
        <PageWrapper>
            <Switch>
                <PrivateRoute exact path="/pool-registration" component={ActionSelect} />
                <PrivateRoute exact path="/pool-registration/privacy-policy" component={PrivacyPolicy} />
                <PrivateRoute exact path="/pool-registration/auth-method" component={AuthMethodSelect} />
                <PrivateRoute exact path="/pool-registration/metadata-validation" component={MetadataAuthentication} />
            </Switch>
        </PageWrapper>
    )
}