import './large-button.css'
import ArrowIcon from "./arrow-icon/ArrowIcon";


export default function LargeButton({title, subtitle = '', disabled = false, onClick}) {
    return (
      <div className={`large-button ${disabled ? 'disabled' : ''}`} onClick={() => onClick?.()}>
        <div className="large-button-icon">
          <ArrowIcon width={30} />
        </div>
        <div className="large-button-content">
          <p className="large-button-title">{ title }</p>
          {subtitle ? <p className="large-button-subtitle">{ subtitle }</p> : null}
        </div>
      </div>
    )
  }