import React, {useEffect} from 'react';
import './star-nav-item.css';
import {useAuth} from "../../context/AuthContext";
import {useHistory} from "react-router-dom";
import {storage} from "../../firebase/firebase.utils";

export default function StarNavItem({ className, bg, imgSrc }) {
    const {currentUser} = useAuth();
    const history = useHistory();
    
  return (
    <button onClick={() => history.push("/profile")}
        className={`btn star-nav-item ${className}`}
        style={{ background: bg }}
    >
      <div className="star-icon" style={ imgSrc ? { padding: "2px" } : { padding: "10px"} }>
        { imgSrc 
          ? <img src={imgSrc} style={{ width: "34px", height: "34px", borderRadius: "50%" }}/>
          : <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="white" d="M13.9634 5.28981C13.8712 4.99399 13.6198 4.78455 13.3235 4.75658L9.28207 4.37371L7.68489 0.471701C7.56697 0.185024 7.29866 0 7.00001 0C6.70136 0 6.43294 0.185024 6.31576 0.471701L4.71859 4.37371L0.676563 4.75658C0.380263 4.78511 0.129359 4.99455 0.0366454 5.28981C-0.0555344 5.58562 0.0295957 5.91008 0.25369 6.11517L3.30865 8.91049L2.4079 13.0504C2.34199 13.3548 2.45522 13.6695 2.69725 13.8521C2.82735 13.9507 2.9802 14 3.13359 14C3.26539 14 3.39731 13.9634 3.51512 13.8899L7.00001 11.7155L10.4843 13.8899C10.7399 14.0493 11.0613 14.0347 11.3028 13.8521C11.5448 13.6695 11.658 13.3548 11.5921 13.0504L10.6914 8.91049L13.7463 6.11517C13.9703 5.91008 14.0556 5.58629 13.9634 5.28981Z"/>
          </svg> }
      </div>
      <span className="link simple-link">{currentUser.displayName ? currentUser.displayName : currentUser.email}</span>
    </button>
  );
}
