import { useEffect, useState } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement('#root');

export default function ExpressionConstructor({ isOpen = false, availableFields = [], availableFunctions = [], availableOps = [], onSubmit, onClose }) {
    const [field, setField] = useState(() => availableFields?.[0] || {})
    const [func, setFunc] = useState({
        value: 'avg',
        label: 'Average'
    })
    const [param, setParam] = useState(120)
    const [operation, setOperation] = useState('=')
    const [value, setValue] = useState('')

    const handleSubmit = (event) => {
        event.preventDefault()
        // TODO: Validate
        onSubmit?.(`${field.value}.${func.value}(${param}) ${operation} ${value}`)
    }
    useEffect(() => {
        setField(availableFields?.[0] || {})
    }, [availableFunctions])

    return (
        <ReactModal isOpen={isOpen} style={{
            overlay: {
                zIndex: 101
            },
            content: {
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
                border: 'none'
            }
        }}>
            <div className="admin-panel">
                <div className="radius-card" style={{border: '1px solid rgb(204, 204, 204)'}}>
                    <form action="#" onSubmit={handleSubmit}>
                        <div className="row wrap">
                            <div className="col wrap self-start m-20">
                                <div className="row mb-10">
                                    <label>Field:</label>
                                    <select required name="field" value={field.value} onChange={(e) => 
                                            setField(availableFields.find(f => f.value === e.target.value))
                                        }>
                                        {availableFields.map(f => (
                                            <option key={f.value} value={f.value}>{f.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row wrap">
                            <div className="col wrap self-start m-20">
                                <div className="row mb-10">
                                    <label>Function:</label>
                                    <select required name="function" value={func.value} onChange={(e) => 
                                            setFunc(availableFunctions.find(f => f.value === e.target.value))
                                        }>
                                        {availableFunctions.map(f => (
                                            <option key={f.value} value={f.value}>{f.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row wrap">
                            <div className="col wrap self-start m-20">
                                <div className="row mb-10">
                                    <label>In last X seconds:</label>
                                    <input
                                        type="number"
                                        required
                                        value={param}
                                        name='param'
                                        onChange={(e) => 
                                            setParam(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row wrap">
                            <div className="col wrap self-start m-20">
                                <div className="row mb-10">
                                    <label>Result:</label>
                                    <select required name="operand" value={operation} onChange={(e) => 
                                            setOperation(e.target.value)
                                        }>
                                        {availableOps.map(op => (
                                            <option key={op} value={op}>{op}</option>
                                        ))}
                                    </select>
                                    <input
                                        type="number"
                                        required
                                        value={value}
                                        name='value'
                                        onChange={(e) => 
                                            setValue(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row wrap">
                            <div className="col wrap self-start m-20">
                                <button className="btn" type="submit">
                                    Add
                                </button>
                            </div>
                            <div className="col wrap self-start m-20">
                                <button className="btn" type="button" onClick={() => onClose?.()}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>    
        </ReactModal>
    )
}