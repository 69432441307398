import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CircledQuestionIcon from "../../components/icon/CircledQuestionIcon";
import Loader from "../../components/loader/Loader";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import { useAPI } from "../../context/APIContext";

export default function TriggersView() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const apiClient = useAPI();
    const [triggers, setTriggers] = useState([])

    useEffect(() => {
        apiClient.get('/alerts/triggers').then(res => {
            setTriggers(res.data)
            setIsLoading(false);
        }).catch((err) => {
            /* eslint no-console: off */
            console.error(err)
            setIsLoading(false);
         });
    }, [apiClient])

    return (
        <PageWrapper>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="admin-panel">
                    <div className="header">
                        <div>
                            <span className="title">
                                Alert Triggers
                                <CircledQuestionIcon />
                            </span>
                        </div>
                    </div>
                    <div className="radius-card">
                        <div className="horizontal-scroll">
                            <table>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Device</th>
                                    <th>Severity</th>
                                    <th>Status</th>
                                    <th>Update</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {triggers.map((trigger) => (
                                        <tr key={trigger.id} style={{background: trigger.active ? '#ff868642' : 'transparent'}}>
                                            <td>{trigger.configName}</td>
                                            <td>{trigger.deviceName}</td>
                                            <td>{trigger.level}</td>
                                            <td>{trigger.active ? 'Active' : 'Inactive'}</td>
                                            <td>{(new Date(trigger.lastUpdate._seconds * 1000)).toLocaleDateString()} {(new Date(trigger.lastUpdate._seconds * 1000)).toLocaleTimeString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </PageWrapper>
    );
}
