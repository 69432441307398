import LocalizedLoader from '../localized-loader/LocalizedLoader';
import './grid-table.css'

export default function GridTable({
    rows = [],
    headers = [],
    rowActions = [],
}) {
    const rowTemplateString = `${headers.map((header) => header.width ?? 'minmax(150px, 1.33fr)').join(' ')} ${
        rowActions.length ? 'minmax(150px, 1.33fr)' : ''
    }`

    return (
        <div className="grid relative" style={{ gridTemplateColumns: rowTemplateString }}>
            <div className="contents">
                {headers.map((header) => (
                    <div key={header.key} className="w-full flex items-center bg-table-header px-2.5 py-2">
                        {header.label}
                    </div>
                ))}
                {rowActions.length ? <div className="bg-table-header" /> : null}
            </div>
            <div className="contents">
                {rows.map((row, index) => (
                    <form key={row.id} onSubmit={(e) => {e.preventDefault(); row.onSubmit?.(e, row)}} className={`contents relative ${row.isMutating ? 'pointer-events-none' : ''}`}>
                        {headers.map((header) => (
                            <div
                                key={header.key}
                                className={`flex items-center px-2.5 py-2 ${
                                    index % 2 === 0 ? 'bg-row-odd' : 'bg-row-even'
                                }`}
                            >
                                {row?.[header.key]}
                            </div>
                        ))}
                        {rowActions.length ? (
                            <div
                                className={`flex items-center gap-2 px-2.5 py-2 ${
                                    index % 2 === 0 ? 'bg-row-odd' : 'bg-row-even'
                                }`}
                            >
                                {rowActions.map((action) =>
                                    (action.enabled && !action.enabled?.(row)) ? null : (
                                        <button
                                            key={action.label}
                                            type={action.type === 'submit' ? 'submit' : 'button'}
                                            onClick={(e) => action?.func(row)}
                                        >
                                            {action.label}
                                        </button>
                                    )
                                )}
                            </div>
                        ) : null}
                        {row.isMutating ? (<LocalizedLoader />) : null}
                    </form>
                ))}
            </div>
        </div>
    )
}
