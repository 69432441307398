import PageWrapper from "../../components/page-wrapper/PageWrapper";
import {useHistory} from "react-router-dom";
import "./not-found.css";

export default function NotFound() {
    const history = useHistory();

    return (
            <PageWrapper>
                <div className="not-found">
                    <div className="header">

                        <div>
                            <span className="title">
                                Whoops!  The page you're looking for does not exist.&nbsp;&nbsp;
                                <button className="link" onClick={() => history.push("/statistics")}>Visit the home page</button>&nbsp;instead.
                            </span>
                        </div>
                        
                    </div>
                </div>
            </PageWrapper>
    );
}
