import CircledQuestionIcon from "../icon/CircledQuestionIcon";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import "./pools-table-view.css"
import "../table/table.css";

export default function PoolsTableView({ pools = [] }) {
    const {currentUser} = useAuth();
    const history = useHistory();
    
    return (
            <div className="radius-card pools-table-view">
                <div className="header">
                    <div>
                        <h3 className="title sub">
                            Your pools<CircledQuestionIcon/>
                        </h3>
                    </div>
                    {/*<div>*/}
                    {/*    <button className="btn" onClick={() => history.push("/pools/add")}>Add new pool</button>*/}
                    {/*</div>*/}
                </div>
                <div className="horizontal-scroll">
                    <table>
                    <thead>
                    <tr>
                        <th>Pool</th>
                        <th>Pool ID</th>
                        <th>Pool ID (Bech32)</th>
                        <th>You are admin</th>
                        <th>Processing status</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {pools.map((pool) => {
                        return (
                                <tr key={pool.poolId}>
                                    <td>{pool.ticker.toUpperCase()}</td>
                                    <td>{pool.poolId}</td>
                                    <td>{pool.poolIdBech32}</td>
                                    <td>{pool.owner === currentUser.uid ? "YES" : "NO"}</td>
                                    <td className="status">
                                            <span className={`badge ${
                                                    pool.status === "accepted"
                                                            ? "blue"
                                                            : pool.status === "processing"
                                                            ? "orange"
                                                            : "red"
                                            }`}>{pool.status}</span>
                                    </td>
                                    <td>
                                        {/*{ pool.owner === currentUser.uid */}
                                        {/*        ? <button className="link" onClick={() => history.push("/pools/" + pool.poolIdBech32 + "/manage")}>Manage pool</button> */}
                                        {/*        : null*/}
                                        {/*}*/}
                                    </td>
                                </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
            </div>
    )
}
