import React from "react";

export default function CaretDown() {
  return (
    <span className="icon">
      <svg
        width="13"
        height="7"
        viewBox="0 0 13 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.07704 6.9282C5.85921 6.9282 5.64141 6.84503 5.47534 6.67904L0.249332 1.45298C-0.0831106 1.12054 -0.0831106 0.581539 0.249332 0.249231C0.581639 -0.0830769 1.12053 -0.0830769 1.453 0.249231L6.07704 4.87354L10.7011 0.249392C11.0335 -0.0829154 11.5724 -0.0829154 11.9047 0.249392C12.2373 0.5817 12.2373 1.1207 11.9047 1.45314L6.67874 6.6792C6.51258 6.84522 6.29478 6.9282 6.07704 6.9282Z"
          fill="#3881F3"
        />
      </svg>
    </span>
  );
}
