import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable';
import GridTable from '../../components/grid-table/GridTable'
import CircledQuestionIcon from '../../components/icon/CircledQuestionIcon'
import Loader from '../../components/loader/Loader'
import PageWrapper from '../../components/page-wrapper/PageWrapper'
import { useAPI } from '../../context/APIContext'
import { useAuth } from '../../context/AuthContext'

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const accessHeaders = [
    { key: 'pool', label: 'Pool' },
    { key: 'owner', label: 'Owner' },
    { key: 'allowRead', label: 'Read only access' },
]

export default function AdminPanelNew() {
    const apiClient = useAPI()
    const { isAdmin } = useAuth();
    const queryClient = useQueryClient()
    const accessInfoQuery = useQuery('accessInfo', () => apiClient.get('/pools/accessInfo').then((res) => res.data))
    const users = accessInfoQuery.data?.users || []
    const pools = accessInfoQuery.data?.pools || []

    const [ownerUpdates, setOwnerUpdates] = useState({})
    const [readAccessUpdates, setReadAccessUpdates] = useState({})
    const [activeUpdates, setActiveUpdates] = useState({})

    const resetUpdates = (id) => {
        const ownerUpdatesCopy = {...ownerUpdates}
        delete ownerUpdatesCopy[id]
        setOwnerUpdates(ownerUpdatesCopy)
        const readAccessUpdatesCopy = {...readAccessUpdates}
        delete readAccessUpdatesCopy[id]
        setReadAccessUpdates(readAccessUpdatesCopy)
    }

    const updateAccessMutation = useMutation({
        mutationFn: ({pool, newOwner, newReadAccess}) => apiClient.put(`/pools/accessInfo/${pool.id}`, {owner: newOwner, readAccess: newReadAccess}).then(res => res.data),
        onSuccess: (data) => {
            queryClient.setQueryData(['accessInfo'], {
                pools: [...accessInfoQuery.data?.pools.filter(d => d.id !== data.id), data],
                users: data.users
            })
            resetUpdates(data.id)
        }
    })

    const onOwnerChange = (id, data) => {
        console.log('Owner change', data)
        setOwnerUpdates({...ownerUpdates, [id]: data})
    }

    const onReadAccessChange = (id, data) => {
        console.log('Read access change', data)
        setReadAccessUpdates({...readAccessUpdates, [id]: data})
    }

    const rows = pools
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((pool) => {
            const user = users.find((u) => u.id === pool.owner)
            return {
                id: pool.id,
                pool: `${pool.ticker} (${pool.poolIdBech32})`,
                owner: (
                    <ReactSelect
                        key={pool.id}
                        name="owner"
                        defaultValue={{ label: user?.email, value: user?.email }}
                        options={users.map((u) => ({ label: u.email, value: u.email }))}
                        className="owner-select w-full"
                        classNamePrefix="users-select"
                        isDisabled={!isAdmin}
                        onChange={(v) => onOwnerChange(pool.id, v)}
                        menuPlacement="auto"
                        menuPortalTarget={document.querySelector('body')}
                    />
                ),
                allowRead: (
                    <CreatableSelect
                        key={pool.id}
                        isMulti
                        name="allowRead"
                        defaultValue={users
                            .filter((u) => pool.allowRead.includes(u.id))
                            .map((u) => ({ label: u.email, value: u.email }))}
                        options={users.map((u) => ({ label: u.email, value: u.email }))}
                        className="owner-select w-full"
                        classNamePrefix="users-select"
                        onChange={(v) => onReadAccessChange(pool.id, v)}
                        menuPlacement="auto"
                        menuPortalTarget={document.querySelector('body')}
                        isValidNewOption={(v) => emailRegex.test(v)}
                        formatCreateLabel={(v) => `Invite ${v} to pool`}
                    />
                ),
                onSubmit: async (e, row) => {
                    const newOwner = ownerUpdates[pool.id]?.value || users.find(u => u.id === pool.owner)?.email || null
                    const newReadAccess = readAccessUpdates[pool.id]?.map(v => v.value) || pool.allowRead.map(poolUser => users.find(u => u.id === poolUser)?.email || false).filter(u => u) || []
                    const mutation = updateAccessMutation.mutateAsync({pool, newOwner, newReadAccess})
                    setActiveUpdates({...activeUpdates, [pool.id]: mutation})
        
                    await mutation
                    const updatesCopy = {...activeUpdates}
                    delete updatesCopy[pool.id]
                    setActiveUpdates(updatesCopy)
                },
                isMutating: activeUpdates[pool.id]
            }
        })
    
    const rowActions = [
        {
            label: 'Update',
            func: () => {},
            type: 'submit',
            enabled: (row) => ownerUpdates[row.id] || readAccessUpdates[row.id]
        }
    ]

    return (
        <PageWrapper>
            {accessInfoQuery.isLoading ? <Loader /> : null}
            <div className="admin-panel">
                <div className="header">
                    <div>
                        <span className="title">
                            User Management
                            <CircledQuestionIcon />
                        </span>
                    </div>
                </div>
                <div className="radius-card">
                    <header className="header">
                        <div>
                            <span className="title sub">User Management</span>
                        </div>
                    </header>
                    <div className="">
                        <GridTable headers={accessHeaders} rowActions={rowActions} rows={rows} />
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}
