import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCzWriL8PdjazWv-PClobhLUi7waSu_AII",
  authDomain: "stakepool247-app.firebaseapp.com",
  projectId: "stakepool247-app",
  storageBucket: "stakepool247-app.appspot.com",
  messagingSenderId: "1083394634081",
  appId: "1:1083394634081:web:7331804372148bce38d520",
  measurementId: "G-M4CVEMYZ8K"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
