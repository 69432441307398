import { useHistory } from "react-router-dom";
import RadiusCard from "../../../components/card/RadiusCard";
import LargeButton from "../../../components/large-button/LargeButton";

export default function ActionSelect({onComplete}) {
    const history = useHistory()

    return (
        <div className="pool-registration">
            <RadiusCard className="p-30 pool-registration-card">
                <h2>Stake Pool Monitoring Service</h2>
                <LargeButton title="Pool Monitoring service" subtitle="Monitor your pool block production and check the health of your pool's infrastructure" onClick={() => history.push('/pool-registration/privacy-policy')} />
            </RadiusCard>
            {/* <RadiusCard className="p-30 pool-registration-card">
                <h2>Stake Pool Management Services</h2>
                <LargeButton title="New Pool Registration" subtitle="We love running Cardano Stake Pools! We will manage your Stake Pool Management, so you can focus on your goals!" disabled />
                <LargeButton title="Existing Pool Migration" subtitle="We will take care of your stake pool infrastructure, so you can focuss on other important tasks." disabled />
                <LargeButton title="Backup BP Node" subtitle="Automated backup solution for your BP Server. We will check your BP health and start a backup BP node in case of an emergency." disabled />
            </RadiusCard> */}
        </div>
    )
}