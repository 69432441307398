import React, { useEffect, useState } from 'react'
import PageWrapper from '../../components/page-wrapper/PageWrapper'
import { firestore, storage } from '../../firebase/firebase.utils'
import TelegramLoginButton from 'react-telegram-login'
import Loader from '../../components/loader/Loader'
import { useAuth } from '../../context/AuthContext'
import './profile.css'
import PoolsTableView from '../../components/pools-table-view/PoolsTableView'
import CircledQuestionIcon from '../../components/icon/CircledQuestionIcon'
import { hasData } from '../../components/utility/utilityFunctions'
import { useAPI } from '../../context/APIContext'
import DevicesView from '../../components/devices-view/DevicesView'
import TelegramNotifications from '../../components/telegram-notifications/TelegramNotifications'

export default function Profile() {
    const [profileInfo, setProfileInfo] = useState({ pools: [] })
    const [profileUpdateFlag, setProfileUpdateFlag] = useState(0)
    const [notification, setNotification] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [photo, setPhoto] = useState(null)
    const [photoObjectUrl, setPhotoObjectUrl] = useState(null)
    const [photoUrl, setPhotoUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const { currentUser, updateProfile, updateEmail, updatePassword } = useAuth()
    const apiClient = useAPI()

    useEffect(() => {
        setIsLoading(true)
        apiClient
            .get('/users/me')
            .then((snapshot) => {
                const data = snapshot.data
                const pProfileInfo = {}
                pProfileInfo['username'] = data.username
                pProfileInfo['email'] = data.email
                pProfileInfo['photoUrlDisplay'] = data.photoUrlDisplay
                pProfileInfo['telegramData'] = data.telegramData

                apiClient
                    .get('/pools?sharedOnly=true')
                    .then((qSnapDataPools) => {
                        pProfileInfo['pools'] = qSnapDataPools.data.map((poolDoc) => {
                            const thisPoolData = poolDoc
                            delete thisPoolData.allowRead
                            thisPoolData.status = 'processing' // todo pool status
                            return thisPoolData
                        })
                        setProfileInfo(pProfileInfo)
                    })
                    .catch(() => setProfileInfo(pProfileInfo))
                    .finally(() => setIsLoading(false))
            })
            .catch(() => setIsLoading(false))
    }, [currentUser.uid, profileUpdateFlag])

    useEffect(() => {
        if (!editMode) {
            setDisplayName('')
            setPhoto(null)
            setPhotoObjectUrl(null)
            setPhotoUrl('')
            setEmail('')
            setPassword('')
        }
    }, [editMode])

    function updateDisplayName() {
        if (hasData(displayName) && currentUser.displayName !== displayName) {
            updateProfile({ displayName: displayName })
                .then(() => {
                    currentUser
                        .reload()
                        .then(() => setNotification('Display name changed'))
                        .catch(() => setNotification('An issue occurred, display name not updated'))
                        .finally(() => setIsLoading(false))
                })
                .catch(() => {
                    setNotification('An issue occurred, display name not updated')
                    setIsLoading(false)
                })
        } else {
            return true
        }
    }
    function uUpdateEmail() {
        if (hasData(email) && currentUser.email !== email) {
            updateEmail(email)
                .then(() => setNotification('Please follow the link in the email we sent you'))
                .catch((err) =>
                    setNotification(err.message ? err.message : 'An issue occurred, email could not be updated')
                )
                .finally(() => setIsLoading(false))
        } else {
            return true
        }
    }
    function uUpdatePassword() {
        if (hasData(password)) {
            updatePassword(password)
                .then(() => setNotification('Password has been updated successfully'))
                .catch((err) =>
                    setNotification(err.message ? err.message : 'An issue occurred, password could not be updated')
                )
                .finally(() => setIsLoading(false))
        } else {
            return true
        }
    }

    function updatePhoto() {
        if (hasData(photo)) {
            setIsLoading(true)
            storage
                .ref('users/' + currentUser.uid + '/profile_photo.jpg')
                .put(photo)
                .then((snapshot) => {
                    snapshot.ref
                        .getDownloadURL()
                        .then((downloadUrl) => {
                            updateProfile({ photoURL: downloadUrl })
                                .then(() => {
                                    setNotification('Photo changed')

                                    const profileInfoCopy = { ...profileInfo }
                                    profileInfoCopy['photoUrlDisplay'] = photo.name
                                    setProfileInfo(profileInfoCopy)

                                    currentUser.reload().finally(() => setIsLoading(false))
                                    apiClient.put('/users/me', { photoUrl: downloadUrl, photoUrlDisplay: photo.name })
                                })
                                .catch(() => {
                                    setNotification('An issue occurred, photo not updated')
                                    setIsLoading(false)
                                })
                        })
                        .catch(() => {
                            setNotification('An issue occurred, photo not updated')
                            setIsLoading(false)
                        })
                })
                .catch(() => {
                    setNotification('An issue occurred, photo not updated')
                    setIsLoading(false)
                })
        } else {
            return true
        }
    }

    function onTelegramAuth(user) {
        apiClient.put('/users/me', { telegramData: user }).then(() => {
            setProfileUpdateFlag(profileUpdateFlag + 1)
        })
    }

    function removeTelegram() {
        setIsLoading(true)
        apiClient.delete('/users/me/telegramData').then(() => {
            setProfileUpdateFlag(profileUpdateFlag + 1)
        })
    }

    async function uUpdateProfile(e) {
        e.preventDefault()
        setIsLoading(true)
        const noPhoto = updatePhoto()
        const noDisplayName = updateDisplayName()
        const noEmail = uUpdateEmail()
        const noPwd = uUpdatePassword()
        if (noPhoto && noDisplayName && noEmail && noPwd) {
            setIsLoading(false)
        }
        setEditMode(false)
    }

    return (
        <PageWrapper>
            {isLoading ? <Loader /> : null}
            <div className="profile">
                <div className="header">
                    <div>
                        <span className="title">
                            User {currentUser.email} profile
                            <CircledQuestionIcon />
                        </span>
                    </div>
                </div>

                <div className="radius-card">
                    <div className="header">
                        <div>
                            <span className="title sub">
                                Profile information
                                <CircledQuestionIcon />
                            </span>
                        </div>
                        <div>
                            {editMode ? (
                                <>
                                    <button
                                        className="link mr-10"
                                        onClick={() => {
                                            setEditMode(false)
                                            setNotification('')
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn"
                                        form="updateProfile"
                                        type="submit"
                                        onClick={() => setNotification('')}
                                    >
                                        Submit changes
                                    </button>
                                </>
                            ) : (
                                <button className="btn" onClick={() => setEditMode(true)}>
                                    Edit profile
                                </button>
                            )}
                        </div>
                    </div>
                    {editMode ? (
                        <form onSubmit={(e) => uUpdateProfile(e)} id="updateProfile">
                            <div className="row wrap">
                                <div className="col wrap photo">
                                    <div className="row">
                                        <img
                                            src={
                                                photoObjectUrl
                                                    ? photoObjectUrl
                                                    : currentUser?.photoURL
                                                    ? currentUser.photoURL
                                                    : '/img/photo_placeholder.png'
                                            }
                                            alt="Profile"
                                        />
                                    </div>
                                    <div className="row items-center self-start input">
                                        <div className="col">
                                            <input
                                                type="file"
                                                disabled={isLoading}
                                                accept="image/*"
                                                value={photoUrl}
                                                onInput={(e) => {
                                                    setPhoto(e.target.files[0])
                                                    setPhotoObjectUrl(URL.createObjectURL(e.target.files[0]))
                                                    setPhotoUrl(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            <button
                                                className={`no ${isLoading ? 'loading' : ''}`}
                                                disabled={isLoading}
                                                type="button"
                                                onClick={() => {
                                                    setPhoto(null)
                                                    setPhotoUrl('')
                                                    setPhotoObjectUrl(null)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col wrap userinfo">
                                    <div className="row items-center self-start">
                                        <label>Display name:</label>
                                        <input
                                            value={displayName}
                                            type="text"
                                            maxLength={20}
                                            disabled={isLoading}
                                            style={{ width: '25ch' }}
                                            placeholder={
                                                currentUser.displayName ? currentUser.displayName : '(max 20 symbols)'
                                            }
                                            onInput={(e) => setDisplayName(e.target.value)}
                                        />
                                        <button
                                            className={`no ${isLoading ? 'loading' : ''}`}
                                            disabled={isLoading}
                                            type="button"
                                            onClick={() => setDisplayName('')}
                                        />
                                    </div>
                                    <div className="row items-center self-start">
                                        <label>E-mail:</label>
                                        <input
                                            value={email}
                                            type="email"
                                            disabled={isLoading}
                                            style={{ width: '25ch' }}
                                            placeholder={currentUser.email}
                                            onInput={(e) => setEmail(e.target.value)}
                                        />
                                        <button
                                            className={`no ${isLoading ? 'loading' : ''}`}
                                            disabled={isLoading}
                                            type="button"
                                            onClick={() => setEmail('')}
                                        />
                                    </div>
                                    <div className="row items-center self-start">
                                        <label>Password:</label>
                                        <input
                                            value={password}
                                            type="password"
                                            disabled={isLoading}
                                            maxLength={30}
                                            style={{ width: '30ch' }}
                                            placeholder="Password (max 30 symbols)"
                                            onInput={(e) => setPassword(e.target.value)}
                                        />
                                        <button
                                            className={`no ${isLoading ? 'loading' : ''}`}
                                            disabled={isLoading}
                                            type="button"
                                            onClick={() => setPassword('')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className="row wrap">
                            <div className="col wrap photo">
                                <img
                                    src={currentUser?.photoURL ? currentUser.photoURL : '/img/photo_placeholder.png'}
                                    alt="Profile"
                                />
                            </div>
                            <div className="col wrap userinfo">
                                <div className="row items-center self-start">
                                    Display name: {currentUser.displayName}
                                </div>
                                <div className="row items-center self-start">Email: {currentUser.email}</div>
                                {profileInfo?.telegramData ? (
                                    <div className="row items-center self-start gap-4">
                                        Telegram Login: {profileInfo?.telegramData?.username || profileInfo?.telegramData?.first_name}
                                        <button className="btn" onClick={() => removeTelegram()}>
                                            Remove
                                        </button>
                                    </div>
                                ): (<>
                                    <TelegramLoginButton dataOnauth={onTelegramAuth} botName={process.env.REACT_APP_TELEGRAM_BOT || 'Stakepool247_Dev_Bot'} />
                                </>)}
                            </div>
                        </div>
                    )}
                </div>
                <PoolsTableView pools={profileInfo.pools} />
                <DevicesView />
                <TelegramNotifications />
            </div>
        </PageWrapper>
    )
}
