import React from 'react';
import LoginForm from '../../components/login-form/LoginForm';
import SignUpForm from "../../components/sign-up-form/SignUpForm";
import NoAuthWrapper from "../../components/no-auth-wrapper/NoAuthWrapper";

export default function NoAuth( { registered = {} } ) {
    return (
            <NoAuthWrapper>
                {registered ? <LoginForm/> : <SignUpForm/>}
            </NoAuthWrapper>
    );
}
