import React, {useEffect, useMemo, useState} from "react";
import PoolBlock from "../../components/pool-block/PoolBlock";
import "./statistics.css";
import BlockStatistics from "../../components/block-statistics/BlockStatistics";
import DelegationGraphContainer from "../../components/delegation-graph/DelegationGraphContainer";
import PoolRewards from "../../components/pool-rewards/PoolRewards";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import {firestore} from "../../firebase/firebase.utils";
import Loader from "../../components/loader/Loader";
import {useAuth} from "../../context/AuthContext";
import {hasData} from "../../components/utility/utilityFunctions";
import {EyeIcon, EyeSlashIcon} from "../../components/icon/EyeIcon";
import {useHistory, useLocation} from "react-router-dom";
import { useAPI } from "../../context/APIContext";
import Dropdown from "../../components/dropdown/Dropdown";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import ServersStatus from "../../components/server-status/ServersStatus";

export default function Statistics() {
    const [pools, setPools] = useState([]);
    const [epochs, setEpochs] = useState([]);
    const [globalPoolData, setGlobalPoolData] = useState({});
    const [selectedEpoch, setSelectedEpoch] = useState(undefined);
    const [error, setError] = useState(null)
    const [selectedPool, setSelectedPool] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [epochsLoading, setEpochsLoading] = useState(true)
    const [hidden, setIsSensitiveHidden] = useState(false);
    const { currentUser, poolCount } = useAuth();
    const apiClient = useAPI()
    const history = useHistory()
    
    const [activePool, setActivePool] = useQueryParam('pool', StringParam);
    const [activeEpoch, setActiveEpoch] = useQueryParam('epoch', NumberParam);

    const handlePoolChange = (pool) => { // on pool change reset epochs, updates selected pools data
        const targetPool = pools.filter(pPool => pPool.poolId === pool.target.value)[0];
        setActivePool(targetPool.poolId)
        setSelectedPool(targetPool);
    };

    const handleEpochChange = (epoch) => {
        const maxEpoch = Math.max((epochs || []).map(e => e.epoch))
        if (maxEpoch && maxEpoch == epoch) {
            setActiveEpoch('latest')
        } else {
            setActiveEpoch(epoch.target.value)
        }
        setSelectedEpoch(epoch.target.value);
    };

    useEffect(() => {
        if (selectedPool.poolId) {
            setEpochsLoading(true)
            setError(null)
            apiClient.get(`/pools/${selectedPool.poolId}/epochs`).then(qSnapDocsEpochs => populateEpochsData(qSnapDocsEpochs.data)).catch((err) => {
                setIsLoading(false);
                setEpochs([]);
                setError('No epochs data available for selected pool.')
            });
        }
    }, [selectedPool])
    
    const populateEpochsData = function (snapDocsEpochs) {
        setEpochs(snapDocsEpochs)
        setEpochsLoading(false)
    }

    const populatePoolsData = function (snapDocsPools) {
        if (snapDocsPools.length === 0) {
            setPools([]);
            setEpochs([]);
            setIsLoading(false);
            // No pools available, redirect to registration
            history.push("/pool-registration");
        } else {
            const poolsInfo = snapDocsPools.sort((a, _) => a.id === selectedPool ? -1 : 1).map(poolData => ({
                poolId: poolData.poolIdBech32,
                ticker: poolData.ticker.toUpperCase(),
                poolStakeAddress: poolData.poolStakeAddress,
                epochs: []
            }));
            setPools(poolsInfo)
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setGlobalPoolData({});
        setSelectedPool({});
        currentUser.getIdTokenResult().then((idTokenResult) => {
            setError(null)
            // Check whether the user is an Admin or simple user
            if (!!idTokenResult.claims.admin) { // todo pagination
                apiClient.get('/pools?all=true').then((qSnapDataPools) => populatePoolsData(qSnapDataPools.data))
                .catch(() => { setPools([]); setEpochs([]); setIsLoading(false); });
            } else {
                apiClient.get('/pools').then(qSnapDocsPools => {
                    populatePoolsData(qSnapDocsPools.data)
                    if (qSnapDocsPools.data.length !== poolCount) {
                        currentUser.getIdToken(true)
                    }
                });
            }
        });
    }, [currentUser.uid]);

    useEffect(() => {
        if (hasData(pools)) {
            let setpool
            if (activePool) {
                setpool = pools.find(pool => pool.poolId === activePool)
            } 
            
            if (setpool) {
                setSelectedPool(setpool);
            } else {
                setpool = {...pools[0]};
                setActivePool(setpool.poolId)
                setSelectedPool(setpool);
            }
        } else {
            setEpochs([]);
            setSelectedPool({});
        }
    }, [pools]);
    
    useEffect(() => {
        if (hasData(epochs)) {
            let setepoch
            if (activeEpoch && activeEpoch !== 'latest') {
                setepoch = epochs.find(epoch => epoch.epoch === activeEpoch)
            }
            
            if (setepoch) {
                setSelectedEpoch(setepoch.epoch);
            } else {
                setActiveEpoch('latest');
                setSelectedEpoch(epochs[0].epoch);
            }
        } else {
            setSelectedEpoch(undefined);
        }
    }, [epochs]);

    return (
        <PageWrapper>
            { isLoading ? <Loader/> : null }
            <div className="statistics">
                {pools.length < 1 ? isLoading ? null : 
                        <header className="header">
                            <div>
                                <span className="title">No pools</span>
                            </div>
                        </header>
                        : <>
                            <header className="header">
                                <div>
                                    <span className="title">Pool { selectedPool.ticker } statistics</span>
                                </div>
                                <div className="filter">
                                    <button className="btn" onClick={() => setIsSensitiveHidden(!hidden)}>{ hidden ? <EyeSlashIcon fill="#3881F3"/> : <EyeIcon fill="#3881F3"/> }</button>
                                    <Dropdown options={pools.map(pool => ({value: pool?.poolId, label: `${pool.ticker}`}))} onChange={handlePoolChange} value={selectedPool?.poolId} />
                                    <Dropdown options={epochs.map(({epoch}) => ({value: epoch, label: `Epoch ${epoch}`}))} onChange={handleEpochChange} value={selectedEpoch} />
                                </div>
                            </header>
                            {error ? (
                                <div className="error">{error}</div>
                            ) : (
                                <>
                                    <ServersStatus pool={selectedPool?.poolId}></ServersStatus>
                                    <div className="pool-statistics">
                                        <DelegationGraphContainer loading={epochsLoading} poolData={selectedPool} epochData={epochs} currentPool={selectedPool} currentEpoch={selectedEpoch}/>
                                        <PoolRewards loading={epochsLoading} poolData={selectedPool} epochData={epochs} currentPool={selectedPool} currentEpoch={selectedEpoch}/>
                                    </div>
                                    <BlockStatistics loading={epochsLoading} poolData={selectedPool} epochData={epochs} currentPool={selectedPool} currentEpoch={selectedEpoch}/>
                                    <PoolBlock poolSelect={<Dropdown options={pools.map(pool => ({value: pool?.poolId, label: `Pool ${pool.ticker}`}))} onChange={handlePoolChange} value={selectedPool?.poolId} />} loading={epochsLoading} poolData={selectedPool} epochData={epochs} currentPool={selectedPool} currentEpoch={selectedEpoch} hidden={hidden}/>
                                </>
                            )}
                        </>
                        
                }
            </div>
        </PageWrapper>
    );
}
