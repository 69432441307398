import "./highlighted-json-view.css";

export default function HighlightedJsonView({ json, highlightedKeys = [] }) {
    return (
        <div className="json-view">
            <p>{"{"}</p>
            <div className="indent">
                {Object.entries(json).map(([key, value], index) => (
                    <pre>
                        <code
                            className={
                                highlightedKeys.indexOf(key) !== -1
                                    ? "highlighted"
                                    : ""
                            }
                        >
                            "{key}": {JSON.stringify(value, null, 4)}
                            {index < Object.keys(json).length - 1 ? "," : null}
                        </code>
                    </pre>
                ))}
            </div>
            <p>{"}"}</p>
        </div>
    );
}
