import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useAPI } from "../../context/APIContext";
import "../../components/login-form/login-form.css";
import EnvelopeIcon from "../../components/icon/EnvelopeIcon";
import NoAuthWrapper from "../../components/no-auth-wrapper/NoAuthWrapper";
import RadiusCard from "../../components/card/RadiusCard";
import { EyeIcon, EyeSlashIcon } from "../../components/icon/EyeIcon";
import { StringParam, useQueryParam } from "use-query-params";

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, confirmPasswordReset } = useAuth();
    const history = useHistory();
    const apiClient = useAPI();
    const [oobCode] = useQueryParam('oobCode', StringParam);

    function inputPassword(e) {
        setPassword(e.target.value);
    }

    function inputConfirmPassword(e) {
        setConfirmPassword(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setSuccessText("");
        setErrorText("");
        if (password !== confirmPassword) {
            setErrorText("Passwords do not match");
            return;
        }
        try {
            setIsLoading(true);
            await confirmPasswordReset(oobCode, password);
            setErrorText("");
            setSuccessText("Password reset successfully");
            setIsLoading(false);
        } catch (error) {
            // console.error(error);
            setErrorText(error.message);
            setIsLoading(false);
        }
    }

    return (
        <NoAuthWrapper>
            <RadiusCard className="login-form" radius="30px">
                <h1 className="heading">Welcome Back</h1>
                <h3 className="sub-heading">StakePool247.io</h3>
                <p className="descriptive-text">Enter you new password</p>
                <form onSubmit={handleSubmit}>
                    <div className="input">
                        <input
                            type={passwordHidden ? "password" : "text"}
                            placeholder="Password"
                            onInput={inputPassword}
                        />
                        {passwordHidden ? (
                            <div
                                className="icon"
                                onClick={() => setPasswordHidden(false)}
                            >
                                <EyeIcon />
                            </div>
                        ) : (
                            <div
                                className="icon"
                                onClick={() => setPasswordHidden(true)}
                            >
                                <EyeSlashIcon />
                            </div>
                        )}
                    </div>
                    <div className="input">
                        <input
                            type={confirmPasswordHidden ? "password" : "text"}
                            placeholder="Confirm Password"
                            onInput={inputConfirmPassword}
                        />
                        {confirmPasswordHidden ? (
                            <div
                                className="icon"
                                onClick={() => setConfirmPasswordHidden(false)}
                            >
                                <EyeIcon />
                            </div>
                        ) : (
                            <div
                                className="icon"
                                onClick={() => setConfirmPasswordHidden(true)}
                            >
                                <EyeSlashIcon />
                            </div>
                        )}
                    </div>
                    <div className="error-text">{errorText}</div>
                    <div className="success-text">{successText}</div>
                    <button
                        type="submit"
                        className="signin-btn"
                        disabled={isLoading}
                    >
                        Reset password
                    </button>
                </form>
            </RadiusCard>
        </NoAuthWrapper>
    );
}
