import RadiusCard from "../card/RadiusCard";
import "../login-form/login-form.css";
import SignUp from "../sign-up/SignUp";

export default function SignUpForm() {
  return (
    <RadiusCard className="login-form" radius="30px">
      <h1 className="heading">Welcome to</h1><h3 className="sub-heading">StakePool247.io</h3>
      <p className="descriptive-text">Sign up for an Account</p>
        <SignUp/>
    </RadiusCard>
  );
}
