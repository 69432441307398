import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CircledQuestionIcon from "../../components/icon/CircledQuestionIcon";
import Loader from "../../components/loader/Loader";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import { useAPI } from "../../context/APIContext";

const deviceTypes = [
    {
        value: "block",
        label: "Block Producing node",
    },
    {
        value: "relay",
        label: "Relay node",
    },
    {
        value: "other",
        label: "Other",
    },
];

export default function DeviceAdd() {
    const { pool, deviceId } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [deviceType, setDeviceType] = useState("other");
    const [externalIp, setExternalIp] = useState("");
    const [poolId, setPoolId] = useState("");

    const [ownedPools, setOwnedPools] = useState([]);

    const apiClient = useAPI();
    const history = useHistory();

    const submitForm = (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (pool && deviceId) {
            apiClient
                .put("/devices", { device: deviceName, externalIp: externalIp, pool: poolId, type: deviceType })
                .then(() => {
                    setIsLoading(false);
                    history.push("/profile");
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        } else {
            apiClient
                .post("/devices", { device: deviceName, externalIp: externalIp, pool: poolId, type: deviceType })
                .then(() => {
                    setIsLoading(false);
                    history.push("/profile");
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        apiClient
            .get(`/pools?ownedOnly=true`)
            .then((pools) => {
                setOwnedPools(pools.data);
                if (pools.data?.length > 0 && !poolId) {
                    setPoolId(pools.data[0].id || '')
                }
                setIsLoading(false);
            })
            .catch(function () {
                setIsLoading(false);
            })
            .finally(() => setIsLoading(false));
    }, [apiClient]);

    useEffect(() => {
        if (pool && deviceId) {
            // setDeviceName(pool)
            setIsLoading(true);
            apiClient
                .get(`/devices/${pool}/${deviceId}`)
                .then((dev) => {
                    setDeviceName(dev.data.name)
                    setExternalIp(dev.data.externalIp || '')
                    setPoolId(dev.data.pool)
                    setDeviceType(dev.data.deviceType || 'other')
                })
                .catch(function () {
                    history.push("/profile");
                })
        }
    }, [apiClient, pool, deviceId])

    return (
        <PageWrapper>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="admin-panel">
                    <div className="header">
                        <div>
                            <span className="title">
                                Add new device
                                <CircledQuestionIcon />
                            </span>
                        </div>
                    </div>
                    <div className="radius-card">
                        <form onSubmit={(e) => submitForm(e)}>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Device name:</label>
                                        <input
                                            type="text"
                                            required
                                            value={deviceName}
                                            disabled={deviceId}
                                            onChange={(e) =>
                                                setDeviceName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Device type:</label>
                                        <select
                                            name="device_type"
                                            required
                                            value={deviceType}
                                            onChange={(e) =>
                                                setDeviceType(e.target.value)
                                            }
                                        >
                                            {deviceTypes.map((type) => (
                                                <option key={type.value} value={type.value}>
                                                    {type.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>Pool ID:</label>
                                        <select
                                            name="pool"
                                            required
                                            value={poolId}
                                            onChange={(e) =>
                                                setPoolId(e.target.value)
                                            }
                                        >
                                            {ownedPools.length > 0 ? (
                                                ownedPools.map((pool) => (
                                                    <option key={pool.id} value={pool.id}>
                                                        {pool.id}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled value="none">
                                                    No owned pools found
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <div className="row mb-10">
                                        <label>External IP:</label>
                                        <input
                                            type="text"
                                            value={externalIp}
                                            onChange={(e) =>
                                                setExternalIp(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row wrap">
                                <div className="col wrap self-start m-20">
                                    <button className="btn" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </PageWrapper>
    );
}
