import React from 'react';
import "./hover-text-icon.css";
import {hasData} from "../utility/utilityFunctions";

export default function CircledQuestionIcon({text = ""}) {
    return (<>
        <div>
        <span className="icon">
      <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="8.25" stroke="#314D6C" strokeWidth="1.5"/>
        <path
                d="M9.62903 11.9528H9.15481C8.82085 11.9528 8.54944 12.2242 8.54944 12.5581V13.213C8.54944 13.5469 8.82085 13.8183 9.15481 13.8183H9.62903C9.963 13.8183 10.2344 13.5469 10.2344 13.213V12.5581C10.2344 12.2232 9.963 11.9528 9.62903 11.9528Z"
                fill="#314D6C"
        />
        <path
                d="M6.28534 6.88361L7.16011 6.99258C7.43757 7.02688 7.70091 6.86746 7.8008 6.60614C7.92692 6.27621 8.10247 6.0159 8.32747 5.82622C8.64631 5.55783 9.04283 5.42263 9.51704 5.42263C10.0084 5.42263 10.3999 5.55178 10.6905 5.80907C10.981 6.06736 11.1253 6.3761 11.1253 6.73832C11.1253 6.99863 11.0436 7.23674 10.8801 7.45266C10.7742 7.58988 10.4483 7.87844 9.90448 8.31936C9.35964 8.76027 8.99743 9.15779 8.81582 9.51093C8.63017 9.87215 8.5656 10.2727 8.54945 10.6743C8.53534 11.0183 8.80977 11.3049 9.15483 11.3049H9.64922C9.96402 11.3049 10.2243 11.0637 10.2526 10.751C10.2728 10.526 10.3101 10.3555 10.3666 10.2394C10.4614 10.0406 10.7066 9.77832 11.1001 9.45142C11.8619 8.82081 12.3583 8.32239 12.5913 7.95512C12.8234 7.58887 12.9404 7.20042 12.9404 6.78977C12.9404 6.04819 12.6246 5.39741 11.992 4.83845C11.3604 4.27948 10.5089 4 9.43936 4C8.42232 4 7.60204 4.27545 6.97648 4.82735C6.44375 5.29752 6.11988 5.85447 6.00586 6.49718C5.97054 6.68484 6.09868 6.8604 6.28534 6.88361Z"
                fill="#314D6C"
        />
      </svg>
    </span>
            {hasData(text)
                    ? <div className="info-textbox">
                        <p>{text}</p>
                    </div>
                    : null
            }
        </div>
    </>);
}
