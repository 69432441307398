import React, { useEffect, useState } from "react";
import RadiusCard from "../card/RadiusCard";
import {BlockStatisticsBarChart} from "./BlockStatisticsBarChart";
import "./block-statistics.css";
import CircledQuestionIcon from "../icon/CircledQuestionIcon";
import {hasData} from "../utility/utilityFunctions";
import LocalizedLoader from "../localized-loader/LocalizedLoader";

export default function BlockStatistics({ loading = false, poolData = {}, epochData = [], currentPool = {}, currentEpoch = {}}) {
  const [graphData, setGraphData] = useState([]);
  const [tickValues, setTickValues] = useState([]);
  const [maxTick, setMaxTick] = useState(0);
  const [minGraphWidth, setMinGraphWidth] = useState("0px");

  useEffect(() => {
    if (!hasData(epochData) || !hasData(currentEpoch)) return;
    const data = epochData
    .filter(({epoch}) => epoch <= currentEpoch)
    .reverse()
    .slice(-15)
    .map(epoch => {
      return {
        "epoch": epoch.epoch,
        "minted": epoch.assignedSlots.filter(slot => slot.status === "minted").length,
        "slot battle": epoch.assignedSlots.filter(slot => slot.status === "failed" && slot.failedReason === "SLOT_BATTLE").length,
        // "near border": epoch.assignedSlots.filter(slot => slot.status === "failed" && slot.failedReason === "NEAR_BORDER").length,
        "orphaned": epoch.assignedSlots.filter(slot => slot.status === "failed" && slot.failedReason === "OTHER").length,
        "failed": epoch.assignedSlots.filter(slot => slot.status === "failed" && (slot.failedReason === "NEAR_BORDER")).length,
        "planned": epoch.assignedSlots.filter(slot => slot.status === "planned").length
      }
    })
    setTickValues(calculateTickValues(data.map((bar) => bar.minted + bar["slot battle"] + bar["orphaned"] + bar["failed"] + bar.planned)));
    setGraphData(data)
    if (hasData(data)) {
      setMinGraphWidth(data.length * 35 + "px");
      const graph = document.getElementById('blockStatisticsGraph');
      graph.scrollLeft = graph.scrollWidth;
    }
  }, [currentEpoch, currentPool, poolData, epochData]);

  const calculateTickValues = function (values) {
    if (!hasData(values)) return [0];
    if (values.length === 1) return [...new Set([0, ...values])];
    const ticks = [];
    const maxValue = Math.max(...values);
    let maxTick;
    let step;
    if (maxValue < 15) {
      maxTick = maxValue + 1;
      step = 1;
    } else {
      maxTick = Math.ceil(maxValue / 10) * 10;
      step = maxValue < 75 ? 5 : 10;
    }
    setMaxTick(maxTick);
    for (let i = 0; i <= maxTick; i+=step) {
      ticks.push(i);
    }
    return ticks;
  }

  return (
    <RadiusCard className="block-statistics">
      <div className="header">
        <span className="title sub">
          Block statistics<CircledQuestionIcon />
        </span>
      </div>
      <div className="horizontal-scroll pv-5" id="blockStatisticsGraph">
        <div style={{ height: "350px", minWidth: minGraphWidth, position: 'relative' }}>
          <BlockStatisticsBarChart data={graphData} tickValues={tickValues} maxTick={maxTick}/>
          {graphData && !loading ? null : <LocalizedLoader />}
        </div>
      </div>
    </RadiusCard>
  );
}
