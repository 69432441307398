import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import EnvelopeIcon from "../icon/EnvelopeIcon";
import { EyeIcon, EyeSlashIcon } from "../icon/EyeIcon";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const history = useHistory();

  function inputEmail(e) {
    setEmail(e.target.value);
  }
  function inputPassword(e) {
    setPassword(e.target.value);
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      await login(email, password);
      setErrorText("");
      setIsLoading(false);
      history.push("/statistics");
    } catch(error) {
      // console.error(error);
      setErrorText(error.message);
      setIsLoading(false);
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="input">
        <input
          type="email"
          placeholder="Email or User Id"
          onInput={inputEmail}
        />
        <EnvelopeIcon />
      </div>
      <div className="input">
        <input
          type={passwordHidden ? "password" : "text"}
          placeholder="Password"
          onInput={inputPassword}
        />
        {passwordHidden ? (
          <div className="icon" onClick={() => setPasswordHidden(false)}>
            <EyeIcon />
          </div>
        ) : (
          <div className="icon" onClick={() => setPasswordHidden(true)}>
            <EyeSlashIcon />
          </div>
        )}
      </div>
      <p className="error small">{errorText}</p>
      <p className="text-right">
        <button className="link" type="button" onClick={(e) => history.push("/forgot-password")}>
          Forgot Password?
        </button>
      </p>
      <button className="signin-btn" type="submit" disabled={isLoading}>
        Sign in
      </button>
      <p className="text-center">Do not have an Account yet?&nbsp;
        <button className="link" onClick={(e) => history.push("/sign-up")}>
          Sign up
        </button>
      </p>
    </form>
  );
}
