import { ResponsiveBar } from "@nivo/bar";

export const PoolRewardsBarChart = ({ data, tickValues }) => (
  <ResponsiveBar
    data={data}
    keys={["rewards"]}
    indexBy="epoch"
    margin={{ top: 10, right: 30, bottom: 15, left: 30 }}
    padding={0.2}
    innerPadding={2}
    groupMode="grouped"
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    valueFormat={{ format: " >-", enabled: false }}
    colors={["#3178c9C1"]}
    borderRadius={2}
    borderColor={{ from: "color", modifiers: [["darker", "1.3"]] }}
    axisTop={null}
    axisRight={{
        tickSize: 0,
        tickValues: tickValues,
    }}
    axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 3
    }}
    axisLeft={{
        tickSize: 0,
        tickValues: tickValues,
    }}
    tooltip={(tooltip) => (
            <div style={{background: 'white', color: 'inherit', alignItems: 'center', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px', padding: '5px 9px'}}>
                <span><strong>{tooltip.data?.rewards?.toFixed(0)} ADA</strong></span>
            </div>
    )}
    enableGridY={true}
    gridYValues={tickValues}
    enableLabel={true}
    labelSkipWidth={0}
    labelSkipHeight={0}
    label={(d) => d.value === 0 ? <tspan y="-10">0</tspan> : ""}
  />
);
