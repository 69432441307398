import {BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./components/helper/helper.css";
import VerifyEmail from "./page/auth/VerifyEmail";
import Statistics from "./page/statistics/Statistics";
import {AuthProvider} from "./context/AuthContext";
import {APIProvider} from "./context/APIContext";
import PrivateRoute from "./components/PrivateRoute";
import NoAuth from "./page/auth/NoAuth";
import Profile from "./page/profile/Profile";
import VerifyConfirm from "./page/auth/VerifyConfirm";
import AddPool from "./page/pools-managing/AddPool";
import ManagePool from "./page/pools-managing/ManagePool";
import DeviceAdd from "./page/device-registration/DeviceAdd";
import React from "react";
import NotFound from "./page/not-found/NotFound";
import Pools from "./page/pools-readonly/Pools";
import MonitorPools from "./page/pools-readonly/MonitorPools";
import RequestResult from "./page/pools-managing/RequestResult";
import ContactUs from "./page/contact-us/ContactUs";
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import AdminPanel from "./page/admin/AdminPanelNew";
import AlertsView from "./page/alerts-config/AlertsView";
import AlertsEdit from "./page/alerts-config/AlertsEdit";
import TriggersView from "./page/alerts-triggers/TriggersView";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import PoolRegistration from "./page/pool-registration/PoolRegistration";
import { QueryClient, QueryClientProvider } from 'react-query'
import ResetPassword from "./page/auth/ResetPassword";
import ForgotPassword from "./page/auth/ForgotPassword";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  return (
    <div className="App">
      <Router>
        <QueryClientProvider client={queryClient}>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <AuthProvider>
              <APIProvider>
                <Switch>
                    {/* Contact Us */}
                    <Route exact path="/contact-us/" component={ContactUs}/>
                    <Route exact path="/contact-us/success">
                        <RequestResult success={true} text="Your message has been sent. We will take a look as soon as we can."/>
                    </Route>
                    <Route exact path="/contact-us/failure">
                        <RequestResult success={false} text="We encountered an issue while sending your message."/>
                    </Route>

                    {/* Sign In */}
                    <Route exact path="/login">
                        <NoAuth registered={true}/>
                    </Route>

                    {/* Sign Up */}
                    <Route exact path="/sign-up/verify">
                        <VerifyEmail text="Click on the link in the email to finish the sign-up process."/>
                    </Route>
                    <Route path="/sign-up/confirm">
                        <VerifyConfirm textSuccess="Your email has been verified." textFail="There has been a problem verifying your email."/>
                    </Route>
                    <Route exact path="/sign-up">
                        <NoAuth registered={false}/>
                    </Route>

                    {/* Password Reset */}
                    <Route exact path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route exact path="/reset-password">
                      <ResetPassword />
                    </Route>

                    {/* Admin Panel */}
                    <PrivateRoute exact path="/admin" component={AdminPanel} />
                    
                    {/* Dashboard */}
                    <PrivateRoute exact path="/statistics" component={Statistics} />
                    <Route exact path="/" render={() => <Redirect to="/statistics" />} />

                    {/* Profile */}
                    <PrivateRoute exact path="/profile" component={Profile} />

                    {/* Device management */}
                    <PrivateRoute exact path="/devices/edit/:pool/:deviceId" component={DeviceAdd} />
                    <PrivateRoute exact path="/devices/add" component={DeviceAdd} />

                    {/* Alert Management */}
                    <PrivateRoute exact path="/triggers" component={TriggersView} />
                    <PrivateRoute exact path="/alerts" component={AlertsView} />
                    <PrivateRoute exact path="/alerts/edit/:id" component={AlertsEdit} />
                    <PrivateRoute exact path="/alerts/edit" component={AlertsEdit} />

                    {/* Pools management */}
                    <PrivateRoute exact path="/pools" component={Pools}/>
                    <PrivateRoute exact path="/pools/monitor" component={MonitorPools}/>
                    {/*<PrivateRoute exact path="/pools/:poolId/manage" component={ManagePool}/>*/}
                    <PrivateRoute path="/pool-registration" component={PoolRegistration} />
                    {/*<PrivateRoute exact path="/pools/add" component={AddPool}/>*/}
                    {/*<PrivateRoute exact path="/pools/add/success">*/}
                    {/*    <RequestResult success={true} text="Your request to add a new pool has been submitted. We will get back to you as soon as possible."/>*/}
                    {/*</PrivateRoute>*/}
                    {/*<PrivateRoute exact path="/pools/add/failure">*/}
                    {/*    <RequestResult success={false} text="We encountered an issue while processing your request to add a new pool."/>*/}
                    {/*</PrivateRoute>*/}

                    {/* Not Found Page */}
                    <PrivateRoute path="/" component={NotFound} />
                </Switch>
              </APIProvider>
            </AuthProvider>
          </QueryParamProvider>
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
