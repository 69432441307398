import React, {useState, useEffect} from "react";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import Loader from "../../components/loader/Loader";
import "./pools.css";
import {hasData} from "../../components/utility/utilityFunctions";
import { useAPI } from "../../context/APIContext";
import NodeStatistics from "../../components/node-statistics/NodeStatistics";
import moment from "moment";
import DateRangeSelect from "./date-range-select/DateRangeSelect";
import ServersStatus from "../../components/server-status/ServersStatus";

export default function MonitorPools() {
    const [errorText, setErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const apiClient = useAPI()

    const [currentDevice, setCurrentDevice] = useState('')
    const [devices, setDevices] = useState([])
    const [metricNames, setMetricNames] = useState([])
    const [allMetrics, setAllMetrics] = useState({})
    const [startTime, setStartTime] = useState(moment().subtract(1, 'hours'))
    const [endTime, setEndTime] = useState(moment())

    const handleDeviceChange = (event) => {
        setCurrentDevice(event.target.value)
    }

    useEffect(() => {
        setIsLoading(true)
        apiClient.get(`/devices`).then(devs => {
            setDevices(devs.data.map(d => d?.name))

            if (!currentDevice && devs.data.length > 0) {
                setCurrentDevice(devs.data[0]?.name)
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
         });
    }, [apiClient])

    useEffect(() => {
        if (!currentDevice) {
            return
        }
        setIsLoading(true);
        apiClient.get(`/metrics?maxPoints=100&deviceIDs=${currentDevice}&startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`).then(metrics => {
            setAllMetrics(metrics.data)
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }, [startTime, endTime, currentDevice])

    useEffect(() => {
        apiClient.get(`/metrics/fields`)
        .then(fields => {
            setMetricNames(fields.data)
        }).catch((err) => {
            setIsLoading(false);
        });
    }, [apiClient])

    return (
            <PageWrapper>
                { isLoading ? <Loader/> : null }
                <div className="monitor-pool">
                    {devices.length < 1 ? isLoading ? null : 
                        <header className="header">
                            <div>
                                <span className="title">No devices registered</span>
                            </div>
                        </header> :
                        <header className="header">
                            <div>
                                <span className="title">
                                    {hasData(errorText)
                                        ? <p className="error">{errorText}</p>
                                        : <h3 className="title">Monitoring</h3>
                                    }
                                </span>
                                <div className="filter">
                                    {devices.length > 1
                                        ? <select style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={handleDeviceChange}>
                                            {devices.map((device) => (<option value={device} key={device}>Device {device}</option>))}
                                        </select>
                                        : <select disabled style={{backgroundImage: "url(/img/caret-down.png)"}} onChange={handleDeviceChange}>
                                            <option value={currentDevice} key={currentDevice}>Device {currentDevice}</option>
                                        </select>}
                                </div>
                                <DateRangeSelect startTime={startTime} endTime={endTime} onSetStart={setStartTime} onSetEnd={setEndTime} />
                            </div>
                        </header>
                    }
                    <ServersStatus></ServersStatus>
                    {metricNames.map(metricName => (
                        <NodeStatistics key={metricName.value} metricData={allMetrics?.[currentDevice]?.[metricName.value]} metric={metricName}/>
                    ))}
                </div>
            </PageWrapper>
    );
}
