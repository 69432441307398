import React, { useEffect, useMemo, useState } from "react";
import { PoolRewardsBarChart } from "./PoolRewardsBarChart";
import "../small-graph-card/small-graph-card.css";
import CaretDown from "../icon/CaretDownIcon";
import { useNumberFormatter } from "../../hooks/number-formatter";
import {hasData} from "../utility/utilityFunctions";
import RadiusCard from "../card/RadiusCard";
import RightArowIcon from "../icon/RightArowIcon";
import LocalizedLoader from "../localized-loader/LocalizedLoader";

export default function PoolRewards({ loading = false, poolData = {}, epochData = [], currentPool = {}, currentEpoch = {} }) {
  const [barData, setBarData] = useState([]);
  // const [tickValues, setTickValues] = useState([]);
  // const [minGraphWidth, setMinGraphWidth] = useState("0px");
  const [previousEpochText, setPreviousEpochText] = useState("");
  const currentEpochData = useMemo(() => epochData.find(({epoch}) => epoch == currentEpoch), [epochData, currentEpoch])
  const displayedEpoch = useMemo(() => currentEpochData?.poolRewards ? currentEpochData : epochData.find(e => e?.poolRewards), [currentEpochData, epochData])
  const totalADA = useMemo(() => displayedEpoch?.poolRewards / 1000000, [displayedEpoch])
  const tickValues = useMemo(() => {
    const values = barData.map((bar) => Math.floor(bar.rewards))
    if (!hasData(values)) return [0];
    if (values.length === 1) return [...new Set([0, ...values])];
    const ticks = [];
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
    let minTens = Math.pow(10, Math.floor(Math.log10(minValue)));
    minTens = minTens === 0 ? 10 : minTens;
    const maxTick = Math.ceil(maxValue / minTens) * minTens;
    let step = maxTick / 4;
    if (step !== Math.round(step)) {
      step = Math.floor((maxTick/4)/10)*10;
    }
    step = step <= 0 ? 1 : step;
    for (let i = 0; i <= maxTick; i+=step) {
      ticks.push(i);
    }
    return ticks;
  }, [barData])
  const totalAdaDisplayed = useNumberFormatter(totalADA, 2)

  const minGraphWidth = useMemo(() => barData?.length * 30 || 0, [barData])

  useEffect(() => {
    if (!hasData(epochData) || !hasData(currentEpoch)) return;
    const bars = epochData
    .filter(({epoch}) => epoch <= currentEpoch)
    .filter(epoch => hasData(epoch.poolRewards))
    .reverse()
    .slice(-15)
    .map((epoch) => {
      return {
        epoch: epoch.epoch,
        rewards: epoch.poolRewards / 1000000
      };
    });

    setBarData(bars);
  }, [currentEpoch, currentPool, epochData, poolData]);

  useEffect(() => {
    if (!hasData(currentEpoch)) {
      setPreviousEpochText("");
      return;
    }
    if (!hasData(currentEpochData?.poolRewards)) {
      setPreviousEpochText("No historical data available for current epoch");
      return;
    }
    
    const prevPoolRewards = epochData.find(({epoch}) => epoch === currentEpoch - 1)?.poolRewards;
    const poolRewards = currentEpochData?.poolRewards;
    // eslint-disable-next-line
    console.log(prevPoolRewards, poolRewards)

    if (prevPoolRewards > poolRewards) { // If previous epoch produced more rewards
      if (poolRewards === 0) {
        setPreviousEpochText((prevPoolRewards / 1000000).toFixed(2) + "M less than previous epoch");
      } else {
        let percent = (prevPoolRewards/poolRewards) * 100 - 100;
        setPreviousEpochText("-" + percent.toFixed(1) + "% less than previous epoch");
      }
    } else if (prevPoolRewards < poolRewards) { // If current epoch produced more rewards
      if (prevPoolRewards === 0) {
        setPreviousEpochText((poolRewards / 1000000).toFixed(2) + "M more than previous epoch");
      } else {
        let percent = (poolRewards / prevPoolRewards) * 100 - 100;
        setPreviousEpochText(percent.toFixed(1) + "% more than previous epoch");
      }
    } else { // If same as previous epoch
      setPreviousEpochText("Equal to previous epoch");
    }

  }, [barData])

  return (
     <RadiusCard className="w-1-2 sm-w-1-1">
        <div className="header">
          <div>
            <span className="title sub">{totalAdaDisplayed} ADA {currentEpoch != displayedEpoch?.epoch ? "(Epoch " + displayedEpoch?.epoch + ")" : null}</span>
          </div>
          <div>
            <div>Total pool owner rewards</div>
          </div>
        </div>
        <div className="horizontal-scroll pv-5" id="poolRewardsGraph">
          <div style={{ height: "140px", minWidth: minGraphWidth, position: 'relative' }}>
            <PoolRewardsBarChart data={barData} tickValues={tickValues} />
            {!loading ? null : <LocalizedLoader />}
          </div>
        </div>
        <hr />
        <div className="bottom">
          <CaretDown />
          <button className="link simple-link ml-10 text-left">{previousEpochText}</button>
        </div>
     </RadiusCard>
  );
}
