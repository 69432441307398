import {ResponsiveBar} from "@nivo/bar";

export const BlockStatisticsBarChart = ({ data, tickValues, maxTick }) => (
        <ResponsiveBar
                data={data}
                keys={['minted', 'slot battle', 'orphaned', 'failed', 'planned']}
                indexBy="epoch"
                margin={{top: 20, right: 30, bottom: 15, left: 30}}
                padding={0.4}
                colors={["#3178c9c1", "#342D52C1", "#7700ffc1", "rgba(253,54,52,0.76)", "#ffa600c1"]}
                yFormat=" >-.2d"
                xFormat=" >-d"
                maxValue={maxTick}
                axisTop={null}
                axisRight={{
                    orient: "right",
                    tickSize: 0,
                    tickPadding: 7,
                    tickRotation: 0,
                    tickValues: tickValues
                }}
                axisBottom={{
                    orient: "bottom",
                    tickSize: 0,
                    tickPadding: 4,
                    tickRotation: 0,
                    legendOffset: 0,
                    legendPosition: "middle",
                }}
                axisLeft={{
                    orient: "left",
                    tickSize: 0,
                    tickPadding: 7,
                    tickRotation: 0,
                    tickValues: tickValues
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -17,
                        itemsSpacing: 5,
                        itemWidth: 80,
                        itemHeight: 10,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: "circle",
                        symbolBorderColor: "rgba(0, 0, 0, .5)"
                    }
                ]}
                gridYValues={tickValues}
                label={(d) => {
                    const total = data.filter(dData => dData.epoch === d.data.epoch).map(dd => dd.minted + dd.failed + dd.planned)[0];
                    return total === 0 ? <tspan y="-10">0</tspan> : d.value === 0 || d.value === 1 ? null : d.value;
                }}
        />
);
