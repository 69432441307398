// Form for handling password reset similar to Register form

import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useAPI } from '../../context/APIContext';
import '../../components/login-form/login-form.css';
import EnvelopeIcon from '../../components/icon/EnvelopeIcon';
import NoAuthWrapper from '../../components/no-auth-wrapper/NoAuthWrapper';
import RadiusCard from '../../components/card/RadiusCard';

export default function ForgotPassword() {

    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, resetPassword } = useAuth();
    const history = useHistory();
    const apiClient = useAPI();

    function inputEmail(e) {
        setEmail(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setSuccessText("");
        try {
            setIsLoading(true);
            await resetPassword(email);
            setErrorText("");
            setSuccessText("Password reset email sent");
            setIsLoading(false);
        } catch(error) {
            setErrorText(error.message);
            setIsLoading(false);
        }
    }

    return (
      <NoAuthWrapper>
        <RadiusCard className="login-form" radius="30px">
          <h1 className="heading">Welcome Back</h1>
          <h3 className="sub-heading">StakePool247.io</h3>
          <p className="descriptive-text">Enter your email to reset password</p>
          <form onSubmit={handleSubmit}>
              <div className="input">
                  <input type="text" placeholder="Email" onInput={inputEmail}/>
                  <EnvelopeIcon />
              </div>
              <div className="error-text">{errorText}</div>
              <div className="success-text">{successText}</div>
              <button type="submit" className="signin-btn" disabled={isLoading}>Reset password</button>
          </form>
        </RadiusCard>
      </NoAuthWrapper>
    )
}