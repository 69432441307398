import React from 'react';
import ContactUsForm from "../../components/contact-us-form/ContactUsForm";
import {useAuth} from "../../context/AuthContext";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import NoAuthWrapper from "../../components/no-auth-wrapper/NoAuthWrapper";

export default function ContactUs() {
    const {currentUser} = useAuth();

    return (<>
        {currentUser && currentUser.emailVerified
                ? <PageWrapper>
                    <ContactUsForm/>
                </PageWrapper>
                : <NoAuthWrapper>
                    <ContactUsForm/>
                </NoAuthWrapper>}
    </>);
}
