import React from 'react';
import RadiusCard from '../../components/card/RadiusCard';
import "../../components/request-notif-card/request-notif-card.css";
import {useHistory} from "react-router-dom";
import PageWrapper from "../../components/page-wrapper/PageWrapper";
import {useAuth} from "../../context/AuthContext";

export default function RequestResult({success = true, text = {}}) {
    const history = useHistory();
    const {currentUser} = useAuth();

    return (<>
        {currentUser && currentUser.emailVerified
                ? <PageWrapper>
                    {success
                            ? <RadiusCard className="request-notif-card" radius="30px">
                                <h1 className="heading">Your request has been submitted</h1>
                                <h3 className="sub-heading">StakePool247.io</h3>
                                <p className="descriptive-text">{text}</p>
                                <span>Return to <button className="link" onClick={() => history.push("/statistics")}>dashboard</button></span>
                            </RadiusCard>
                            : <RadiusCard className="request-notif-card" radius="30px">
                                <h1 className="heading">There has been a problem processing your request</h1>
                                <h3 className="sub-heading">StakePool247.io</h3>
                                <p className="descriptive-text">{text}</p>
                                <span>Please <button className="link" onClick={() => history.goBack()}>try again</button> or <button className="link" onClick={() => history.push("/contact-us")}>contact us for support</button></span>
                            </RadiusCard>
                    }
                </PageWrapper>
                : success
                        ? <RadiusCard className="request-notif-card" radius="30px">
                            <h1 className="heading">Your request has been submitted</h1>
                            <h3 className="sub-heading">StakePool247.io</h3>
                            <p className="descriptive-text">{text}</p>
                            <span>Return to <button className="link" onClick={() => history.push("/login")}>login</button></span>
                        </RadiusCard>
                        : <RadiusCard className="request-notif-card" radius="30px">
                            <h1 className="heading">There has been a problem processing your request</h1>
                            <h3 className="sub-heading">StakePool247.io</h3>
                            <p className="descriptive-text">{text}</p>
                            <span>Please <button className="link" onClick={() => history.goBack()}>try again</button> or <button className="link" onClick={() => history.push("/contact-us")}>contact us for support</button></span>
                        </RadiusCard>
        }
    </>);
}
