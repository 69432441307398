import React, {useState} from 'react';
import './page-wrapper.css';
import SideNav from '../side-nav/SideNav';
import TopNav from '../top-nav/TopNav';

export default function PageWrapper({children}) {
    const [ showNav, setShowNav ] = useState(false);
    const triggerSideNav = function () {
        setShowNav(!showNav);
    }
    
  return (
    <div className={showNav ? "toggled-side-nav page-wrapper" : "page-wrapper"}>
      <TopNav/>
      <SideNav show={showNav}/>
      <button className="btn toggle-side-nav-btn" onClick={() => triggerSideNav()} style={{backgroundImage: "url(img/caret-left.png)"}}/>
      <div className="page-container custom-scrollbar">
        {children}
      </div>
      <div className="page-footer">
          <div>
              <h3>Email</h3>
              <a href="mailto:info@stakepool247.io">info@stakepool247.io</a>
          </div>
          <div>
              <h3>Telegram</h3>
              <a href="tg://resolve?domain=stakepool247">@stakepool247</a>
          </div>
          <div>
              <h3>Phone</h3>
              <a href="tel:+37126515476">+371 26515476</a>
          </div>
      </div>
    </div>
  )
}
