import React from 'react';
import "./loader.css";

export default function Loader() {
  return (
    <div className="loader-container">
      <div className="loader">Loading...</div>
    </div>
  )
}
