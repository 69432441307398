import React, {useState} from 'react';
import StarNavItem from '../star-nav-item/StarNavItem';
import './top-nav.css'
import LiveStatsBlock from "../live-stats-block/LiveStatsBlock";
import {useAuth} from "../../context/AuthContext";
import {auth} from "../../firebase/firebase.utils";

export default function TopNav() {
    const {currentUser} = useAuth();
    
  return (
    <div className="top-nav">
      <LiveStatsBlock/>
      <div className="sm-hidden">
        {/*<div className="btn">*/}
        {/*  <svg*/}
        {/*    width="17"*/}
        {/*    height="17"*/}
        {/*    viewBox="0 0 17 17"*/}
        {/*    fill="none"*/}
        {/*    xmlns="http://www.w3.org/2000/svg"*/}
        {/*  >*/}
        {/*    <path*/}
        {/*      d="M16.1492 14.0942C14.8892 13.0292 14.1667 11.4725 14.1667 9.82333V7.5C14.1667 4.5675 11.9883 2.14 9.16667 1.73333V0.833333C9.16667 0.3725 8.79333 0 8.33333 0C7.87333 0 7.5 0.3725 7.5 0.833333V1.73333C4.6775 2.14 2.5 4.5675 2.5 7.5V9.82333C2.5 11.4725 1.7775 13.0292 0.51 14.1008C0.185833 14.3783 0 14.7817 0 15.2083C0 16.0125 0.654167 16.6667 1.45833 16.6667H15.2083C16.0125 16.6667 16.6667 16.0125 16.6667 15.2083C16.6667 14.7817 16.4808 14.3783 16.1492 14.0942Z"*/}
        {/*      fill="#1A395C"*/}
        {/*      fillOpacity="0.7"*/}
        {/*    />*/}
        {/*  </svg>*/}
        {/*</div>*/}
          
        <StarNavItem imgSrc={currentUser?.photoURL} />
      </div>
    </div>
  );
}
