import React from 'react';
import "./radius-card.css";

export default function RadiusCard({radius, bgColor, style, className, children}) {
  return (
    <div 
      className={`radius-card ${className}`}
      style={{
        borderRadius: radius || "10px",
        backgroundColor: bgColor,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
